
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';
import YumurtlamaImg from '../../assets/yumurtlama.jpeg'
export function GebelikBakimi() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <Link><TranslateStatic string="GebelikBakimi" /></Link>
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <div className='banner'>
          <div className='baslik'>
            <h1>
              <TranslateStatic string="GebelikBakimi" />
            </h1>
             <p> <TranslateStatic string="SonGuncellemeTarihi" /> 19.02.2025</p>
          </div>
          <div className='imgwrap'>

          <img className='foregroundImg' src={YumurtlamaImg} />
          <img className="backgroundImg" src={YumurtlamaImg} />
          </div>



        </div>

        <p>
          <TranslateStatic string="gebeliktakibiUsP1" />
        </p>
        <h3><TranslateStatic string="gebeliktakibiUsh3-1" /></h3>
        <p>
          <TranslateStatic string="gebeliktakibiUsP2" />
        </p>
    
        <h3><TranslateStatic string="gebeliktakibiUsh3-2" /></h3>
        <p>
          <TranslateStatic string="gebeliktakibiUsP3" />
        </p>
       
        <h3><TranslateStatic string="gebeliktakibiUsh4-1" /></h3>
        <p>
          <TranslateStatic string="gebeliktakibiUsP4" />
        </p>
       
        <h3><TranslateStatic string="gebeliktakibiUsh5-1" /></h3>
        <p>
          <TranslateStatic string="gebeliktakibiUsP5" />
        </p>

        <h3><TranslateStatic string="gebeliktakibiUsh6-1" /></h3>
        <p>
          <TranslateStatic string="gebeliktakibiUsP6" />
        </p>
       

        <h3><TranslateStatic string="gebeliktakibiUsh7-1" /></h3>
        <p>
          <TranslateStatic string="gebeliktakibiUsP7" />
        </p>

        <h3><TranslateStatic string="gebeliktakibiUsh8-1" /></h3>
        <p>
          <TranslateStatic string="gebeliktakibiUsP8" />
        </p>

        <p>
          <b><TranslateStatic string="gebeliktakibiUsP9" /></b>
        </p>
      </S.Section>
    </S.Wrapper >
  );
}
