
import { Link } from 'react-router-dom';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic';
import YumurtlamaImg from '../../assets/tibbi-genetik.jpg'
export function UremeGuclugu() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link>  >  <Link><TranslateStatic string="UremeGuclugu" /></Link>
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <div className='banner'>
          <div className='baslik'>
            <h1>
              <TranslateStatic string="UremeGuclugu" />
            </h1>
             <p> <TranslateStatic string="SonGuncellemeTarihi" /> 19.02.2025</p>
            {/* <h3>
            <TranslateStatic string="genetik tarama2" />
            </h3> */}
          </div>
          <div className='imgwrap'>

          <img className='foregroundImg' src={YumurtlamaImg} />
          <img className="backgroundImg" src={YumurtlamaImg} />
          </div>



        </div>

        <p>
          <TranslateStatic string="UremeGucluguUsP1" />
        </p>
        <h3><TranslateStatic string="UremeGucluguUsh3-1" /></h3>
        <p>
          <TranslateStatic string="UremeGucluguUsP2" />
        </p>
        <h3><TranslateStatic string="UremeGucluguUsh3-2" /></h3>
        <p>
          <TranslateStatic string="UremeGucluguUsP3" />
        </p>
        <h3><TranslateStatic string="UremeGucluguUsh4-1" /></h3>
        <p>
          <TranslateStatic string="UremeGucluguUsP4-1" />
        </p>
        <p>
          <TranslateStatic string="UremeGucluguUsP4-2" />
        </p>
        <ul>
          <li>
          <TranslateStatic string="Ureme-1" />
          </li>
          <li>
          <TranslateStatic string="Ureme-2" />
          </li>
          <li>
          <TranslateStatic string="Ureme-3" />
          </li>
          <li>
          <TranslateStatic string="Ureme-4" />
          </li>
          <li>
          <TranslateStatic string="Ureme-5" />
          </li>
        
        </ul>
        <p>
          <TranslateStatic string="UremeMaddeAltı" />
        </p>
        <h3>
          <TranslateStatic string="Uremeh3-3" />
        </h3>
        <p>
          <TranslateStatic string="UremeP5" />
        </p>
        <h3>
          <TranslateStatic string="Uremeh3-4" />
        </h3>
        <p>
          <TranslateStatic string="UremeP6" />
        </p>
        <h3>
          <TranslateStatic string="Uremeh3-5" />
        </h3>
        <p>
          <TranslateStatic string="UremeP7" />
        </p>
        <h3>
          <TranslateStatic string="Uremeh3-6" />
        </h3>
        <article>
        <b><TranslateStatic string="UremeMini-1" /></b>
        </article>
        <p>
          <TranslateStatic string="UremeP8" />
        </p>
        <article>
        <b><TranslateStatic string="UremeMini-2" /></b>
        </article>
        <p>
          <TranslateStatic string="UremeP9" />
        </p>
        <article>
        <b><TranslateStatic string="UremeMini-3" /></b>
        </article>
        <p>
          <TranslateStatic string="UremeP10" />
        </p>
        <article>
        <b><TranslateStatic string="UremeMini-4" /></b>
        </article>
        <p>
          <TranslateStatic string="UremeP11" />
        </p>
        <article>
        <b><TranslateStatic string="UremeMini-5" /></b>
        </article>
        <p>
          <TranslateStatic string="UremeP12" />
        </p>
      </S.Section>
    </S.Wrapper >
  );
}
