import S from "../pages/Product/style";

const data = [
    {
        category: {
            id: 5,
            name: '2015',
            imgsrc: [process.env.PUBLIC_URL + '/images/category/2015.jpeg'],
            content: 'Kuru Kimyevi Tozlu, Karbondioksitli, Köpüklü, Sulu yangın söndürme cihazları.',
            link: '/category/2015/5/subcategories',
            subcategories: [
                {
                    id: 0,
                    name: 'Haziran',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Haziran/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Haziran/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Haziran/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Haziran/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Haziran/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Haziran/6.jpg',
                    ],
                    content: '',
                    link: '/product/5/0/haziran',
                    detail: <div className="detail">
                        <h3>Prof. Dr. Erol TAVMERGEN</h3>
                        <article>Konu: YÜT'DE GÜNCEL YAKLAŞIMLAR</article>
                    </div>
                },
                {
                    id: 1,
                    name: 'Temmuz',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Temmuz/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Temmuz/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Temmuz/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Temmuz/4.jpg',
                    ],
                    link: '/product/5/1/temmuz',
                    detail: <div className="detail">
                        <h3>Dr. Mustafa Kemal ÖZEL</h3>
                        <article>Konu : GİRİŞİMSEL OLMAYAN PRENATAL TANI</article>
                    </div>
                },
                {
                    id: 2,
                    name: 'Ağustos',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Agustos/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Agustos/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Agustos/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Agustos/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Agustos/5.jpg',

                    ],
                    link: '/product/5/2/agustos',
                    detail: <div className="detail">
                        <h3>Dr.Tonguç GÜNDÜZ</h3>
                        <article>Konu : SPERM KALİTESİ:HANGİ DURUMDA HANGİ TEDAVİ ?</article>
                        <h3>Yrd. Doç.Esra Nur TOLA</h3>
                        <article>Konu : OHSS</article>
                    </div>
                },
                {
                    id: 3,
                    name: 'Eylül',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Eylul/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Eylul/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Eylul/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Eylul/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Eylul/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Eylul/6.jpg',
                    ],
                    link: '/product/5/3/eylul',
                    detail: <div className="detail">
                        <h3>Prof. Dr.Münire ERMAN AKAR</h3>
                        <article>Konu : FERTİLİTE PREZERVASYONU İÇİN ENDİKASYONLAR VE GÜVENLİ TEDAVİ PROTOKOLLERİ</article>
                    </div>
                },
                {
                    id: 4,
                    name: 'Ekim',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Ekim/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Ekim/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Ekim/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Ekim/4.jpg',
                    ],
                    link: '/product/5/4/ekim',
                    detail: <div className="detail">
                        <h3>Dr.Figen DEMİR</h3>
                        <article>Konu : HASTA YÖNETİMİ</article>
                        <h3>Dr.Barış MÜLAYİM</h3>
                        <article>Konu : İNFERTİLİTEDE ENDOSKOPİNİN YERİ</article>
                    </div>
                },
                {
                    id: 5,
                    name: 'Kasım',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Kasim/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Kasim/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Kasim/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Kasim/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Kasim/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Kasim/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Kasim/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Kasim/8.jpg',
                    ],
                    link: '/product/5/5/kasim',
                    detail: <div className="detail">
                        <h3>Dr.Barış ATA</h3>
                        <article>Konu : İNFERTİLİTEDE YAPILMAMASI GEREKENLER</article>
                        <h3>Dr.Batu AYDINURAZ</h3>
                        <article>Konu : İNFERTİL HASTALARA GENEL YAKLAŞIM</article>
                    </div>
                },
                {
                    id: 6,
                    name: 'Aralık',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Aralik/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Aralik/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Aralik/3.jpg',
                    ],
                    link: '/product/5/6/aralik',
                    detail: <div className="detail">
                        <h3>Jinekolog Onkolog Tayfun TOPTAŞ</h3>
                        <article>Konu : ONKOLOJİDE ROBOTİK CERRAHİNİN YERİ</article>
                        <h3>Prof. Dr.Aysel DERBENT UYSAL</h3>
                        <article>Konu : JİNEKOLOJİDE ROBOTİK CERRAHİNİN YERİ</article>
                        <h3>Dr.Ünal YİRMİBEŞ</h3>
                        <article>Konu : NON-SELEKTİF L\S HİSTEREKTOMİ</article>
                    </div>
                },
            ]
        }
    },
    {
        category: {
            id: 6,
            name: '2016',
            imgsrc: [process.env.PUBLIC_URL + '/images/category/2016.jpeg'],
            content: 'Kuru Kimyevi Tozlu, Karbondioksitli, Köpüklü, Sulu yangın söndürme cihazları.',
            link: '/category/2016/6/subcategories',
            subcategories: [
                {
                    id: 0,
                    name: 'Ocak',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Ocak/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Ocak/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Ocak/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Ocak/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Ocak/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Ocak/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Ocak/7.jpg',
                    ],
                    content: '',
                    link: '/product/6/0/ocak',
                    detail: <div className="detail">
                        <h3>Prof. Dr.N.Erkut ATTAR</h3>
                        <article>Konu : ENDOMETRİOZİSTE MEDİKAL YAKLAŞIM</article>
                        <h3>Prof. Dr.Tayup ŞİMŞEK</h3>
                        <article>Konu : ENDOMETRİOZİSTE CERRAHİ YAKLAŞIM</article>
                    </div>
                },
                {
                    id: 1,
                    name: 'Şubat',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Subat/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Subat/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Subat/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Subat/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Subat/5.jpg',
                    ],
                    link: '/product/6/1/subat',
                    detail: <div className="detail">
                        <h3>Prof. Dr.Batuhan ÖZMEN (Ankara Ünv.)</h3>
                        <article>Konu : REPRODÜKTİF CERRAHİ</article>
                        <h3>Doç. Dr.Gürkan BOZDAĞ (Hacettepe Ünv.)</h3>
                        <article>Konu : TÜRKİYE'DE PCOS PREVALANSI</article>
                    </div>
                },
                {
                    id: 2,
                    name: 'Nisan',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Nisan/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Nisan/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Nisan/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Nisan/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Nisan/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Nisan/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Nisan/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/Nisan/8.jpg',

                    ],
                    link: '/product/6/2/nisan',
                    detail: <div className="detail">
                        <h3>TBMD / Her Yönüyle OHSS Sempozyumu</h3>
                       
                    </div>
                },
                {
                    id: 3,
                    name: 'Haziran',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Haziran/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Haziran/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Haziran/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Haziran/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Haziran/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Haziran/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Haziran/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Haziran/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Haziran/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Haziran/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Haziran/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Haziran/12.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Haziran/13.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Haziran/14.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Haziran/15.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Haziran/16.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Haziran/17.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Haziran/18.jpg',
                    ],
                    link: '/product/6/3/haziran',
                    detail: <div className="detail">
                        <h3>Hemşire Toplantısı</h3>
                        <article>Konu : İNTERTİLİTE HEMŞİRELİĞİNDE GÜNCEL YAKLAŞIM</article>
                        <h3>Doç. Dr.Samet Topuz</h3>
                        <article>Konu : SERVİKAL PREİNVAZİV HASTALIKLARA YÖNETİM.TARAMADA NELER DEĞİŞTİ, HPV Mİ SMEAR Mİ ?</article>
                        <h3>Doç. Dr.Pınar Çilesiz Göksedef</h3>
                        <article>Konu : SERVİKAL PREİNVAZİV LEZYONLARIN DEĞERLENDİRMESİNDE KOLPOSKOPİ.
KOLPOSKOPİK PATOLOJİLERDE YENİ NELER VAR, DAHA OBJEKTİF NASIL OLUNUR ?</article>
                    </div>
                },
                {
                    id: 4,
                    name: 'Eylül',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Eylul/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Eylul/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Eylul/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Eylul/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Eylul/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Eylul/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Eylul/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Eylul/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Eylul/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Eylul/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Eylul/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Eylul/12.jpg',
                    ],
                    link: '/product/6/4/eylul',
                    detail: <div className="detail">
                        <h3>Prof. Dr.Selahattin KUMRU (Akdeniz Ünv.)</h3>
                        <article>Konu : GEBELİKTE DİABET TARAMASI</article>
                        <h3>Prof. Dr.Özgür DÜNDAR (Maltepe Ünv.)</h3>
                        <article>Konu : SERVİKS UZUNLUĞUNUN ÖLÇÜMÜ VE KISA SERVİKSE YAKLAŞIM</article>
                    </div>
                },
                {
                    id: 5,
                    name: 'Ekim',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Ekim/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Ekim/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Ekim/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Ekim/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Ekim/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Ekim/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Ekim/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Ekim/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Ekim/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Ekim/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Ekim/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Ekim/12.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Ekim/13.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Ekim/14.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Ekim/15.jpg',
                    ],
                    link: '/product/6/5/ekim',
                    detail: <div className="detail">
                        <h3>Prof. Dr.Mehmet YILMAZER (Kocatepe Ünv.)</h3>
                        <article>Konu : MİNİMAL İNVAZİV LAPAROSKOPİK HİSTEREKTOMİ</article>
                        <h3>Prof. Dr.Mete GÜNGÖR (Acıbadem Ünv.)</h3>
                        <article>Konu : ROBOTİK CERRAHİ</article>
                    </div>
                },
                {
                    id: 6,
                    name: 'Kasım',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/12.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/13.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/14.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/15.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/16.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/17.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/18.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Kasim/19.jpg',
                    ],
                    link: '/product/6/6/kasim',
                    detail: <div className="detail">
                        <h3>Prof. Dr.Recep HAS (İst. Ünv.)</h3>
                        <article>Konu : PRENATAL TANI: DÜNÜ BUGÜNÜ YARINI</article>
                        <h3>Prof. Dr.Muhterem BAHÇE (Gen Lab. Ank.)</h3>
                        <article>Konu : PRENATAL TANI YÖNTEMLERİ</article>
                    </div>
                },
                {
                    id: 7,
                    name: 'Aralık',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Aralik/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Aralik/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Aralik/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Aralik/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Aralik/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Aralik/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Aralik/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Aralik/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Aralik/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Aralik/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Aralik/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2016-Aralik/12.jpg',
                    ],
                    link: '/product/6/7/aralik',
                    detail: <div className="detail">
                        <h3>Dr.Mehmet ÖZTÜRKMEN</h3>
                        <article>Konu : KLİNİSYEN GÖZÜYLE HSG</article>
                    </div>
                },
            ]
        }
    },
    {
        category: {
            id: 7,
            name: '2017',
            imgsrc: [process.env.PUBLIC_URL + '/images/category/2017.jpeg'],
            content: 'Kuru Kimyevi Tozlu, Karbondioksitli, Köpüklü, Sulu yangın söndürme cihazları.',
            link: '/category/2017/7/subcategories',
            subcategories: [
                {
                    id: 0,
                    name: 'Ocak',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Ocak/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Ocak/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Ocak/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Ocak/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Ocak/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Ocak/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Ocak/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Ocak/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Ocak/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Ocak/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Ocak/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Ocak/12.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Ocak/13.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Ocak/14.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Ocak/15.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Ocak/16.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Ocak/17.jpg',
                    ],
                    content: '',
                    link: '/product/7/0/ocak',
                    detail: <div className="detail">
                        <h3>Prof. Dr.Kutay BİBEROĞLU</h3>
                        <article>Konu : TÜM YÖNLERİYLE ÜREME TIBBI. DÜNYADA VE TÜRKİYE'DE DURUM NEDİR ?</article>
                        <h3>Prof. Dr.Ahmet Zeki IŞIK</h3>
                        <article>Konu : OOSİT KRİYOPREZERVASYONUNDA NERDEYİZ ?</article>
                        <h3>Doç. Dr.Kubilay VİCDAN</h3>
                        <article>Konu : KÖTÜ OVER REZERVİNDE OOSİT DONDURMA GEÇERLİ BİR ENDİKASYON MUDUR ?</article>
                    </div>
                },
                {
                    id: 1,
                    name: 'Şubat',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Subat/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Subat/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Subat/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Subat/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Subat/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Subat/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Subat/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Subat/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Subat/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Subat/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Subat/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Subat/12.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Subat/13.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Subat/14.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Subat/15.jpg',
                    ],
                    link: '/product/7/1/subat',
                    detail: <div className="detail">
                        <h3>Prof. Dr.Önay YALÇIN</h3>
                        <article>Konu : ÜRİNER İNKONTİNANS VE PELVİK ORGAN PROLAPSUSUNDA DEĞERLENDİRME ALGORİTMALARI: UYGULAMADA EKSİKLER VE YANLIŞLARIMIZ...</article>
                        <h3>Doç. Dr.Funda Güngör UĞUR</h3>
                        <article>Konu : KONSERVATİF TEDAVİLER: NE ZAMAN ? NASIL ?</article>
                        <h3>Prof. Dr.Fuat DEMİRCİ</h3>
                        <article>Konu : CERRAHİ TEDAVİLER: HANGİ HASTAYA, HANGİ YÖNTEM ?</article>
                    </div>
                },
                {
                    id: 2,
                    name: 'Mart',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/12.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/13.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/14.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/15.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/16.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/17.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/18.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/19.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/20.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/21.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/22.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/23.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Mart/24.jpg',
                    ],
                    link: '/product/7/2/mart',
                    detail: <div className="detail">
                        <h3>Prof. Dr.Cem Somer ATABEKOĞLU</h3>
                        <article>Konu : POLİKİSTİK OVERDE OVÜLASYON İNDÜKSİYONU</article>
                        <h3>Doç. Dr.Salih TAŞKIN</h3>
                        <article>Konu : FERTİLİTE KORUYUCU JİNEKOLOJİK KANSER CERRAHİSİ</article>
                    </div>
                },
                {
                    id: 3,
                    name: 'Nisan',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Nisan/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Nisan/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Nisan/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Nisan/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Nisan/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Nisan/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Nisan/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Nisan/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Nisan/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Nisan/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Nisan/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Nisan/12.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Nisan/13.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Nisan/14.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Nisan/15.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Nisan/16.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Nisan/17.jpg',

                    ],
                    link: '/product/7/3/nisan',
                    detail: <div className="detail">
                        <h3>Doç. Dr.Murat ÖZEKİNCİ</h3>
                        <article>Konu : POI VE POF : TANI VE GÜNCEL YAKLAŞIM</article>
                        <h3>Doç. Dr.Nasuh Utku DOĞAN</h3>
                        <article>Konu : HRT VE KANSER İLİŞKİSİ: KİME, NE ZAMAN, NE KADAR SÜRE ?</article>
                    </div>
                },
                {
                    id: 4,
                    name: 'Haziran',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Haziran/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Haziran/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Haziran/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Haziran/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Haziran/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Haziran/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Haziran/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Haziran/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Haziran/9.jpg',
                    ],
                    link: '/product/7/4/haziran',
                    detail: <div className="detail">
                        <h3>Doç. Dr.İbrahim KALELİOĞLU</h3>
                        <article>Konu : İLK ÜÇ AY SONOGRAFİSİ</article>
                        <h3>Prof. Dr.Atıl YÜKSEL</h3>
                        <article>Konu : FETAL NÖROSONOGRAFİ</article>
                    </div>
                },
                {
                    id: 7,
                    name: 'Aralık',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Aralik/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Aralik/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Aralik/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Aralik/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Aralik/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Aralik/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Aralik/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Aralik/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Aralik/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2017-Aralik/10.jpg',
                    ],
                    link: '/product/7/7/aralik',
                    detail: <div className="detail">
                        <h3>Doç. Dr.C.Mutlu ERCAN</h3>
                        <article>Konu : Tekrarlayan gebelik kaybı "değerlendirme"</article>
                        <h3>Doç. Dr.Mehmet SAKINCI</h3>
                        <article>Konu : Tekrarlayan gebelik kaybı "yönetim"</article>
                    </div>
                },
            ]
        }
    },
    {
        category: {
            id: 8,
            name: '2018',
            imgsrc: [process.env.PUBLIC_URL + '/images/category/2018.jpeg'],
            content: 'Kuru Kimyevi Tozlu, Karbondioksitli, Köpüklü, Sulu yangın söndürme cihazları.',
            link: '/category/2018/8/subcategories',
            subcategories: [
                {
                    id: 0,
                    name: 'Ocak',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Ocak/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Ocak/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Ocak/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Ocak/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Ocak/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Ocak/6.jpg',
                    ],
                    content: '',
                    link: '/product/8/0/ocak',
                    detail: <div className="detail">
                        <h3>Dr.Funda GÜNGÖR UĞURLUCAN</h3>
                        <article>Konu : Cinsel ağrı bozukluklarında jinekoloğun yeri ve tedavi modaliteleri</article>
                        <h3>Dr.Süleyman Engin AKHAN</h3>
                        <article>Konu : Hipertonik pelvik taban bozuklukları ve cinsellik üzerine etkileri</article>
                    </div>
                },
               
                {
                    id: 2,
                    name: 'Mart',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Mart/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Mart/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Mart/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Mart/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Mart/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Mart/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Mart/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Mart/8.jpg',
                    ],
                    link: '/product/8/2/mart',
                    detail: <div className="detail">
                        <h3>PANEL - Üreme Tedavilerinde Akla Takılanlar</h3>
                        <article>
PANEL - Üreme Tedavilerinde Akla Takılanlar
Dr. Batu AYDINURAZ - Dr. Emb. Enver Kerem DİRİCAN - Dr. Tonguç GÜNDÜZ - Dr. Mete IŞIKOĞLU
Dr. Murat ÖZEKİNCİ - Dr. Mehmet ÖZTÜRKMEN</article>
        
                    </div>
                },
                {
                    id: 3,
                    name: 'Nisan',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Nisan/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Nisan/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Nisan/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Nisan/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Nisan/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Nisan/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Nisan/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Nisan/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Nisan/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Nisan/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Nisan/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Nisan/12.jpg',

                    ],
                    link: '/product/8/3/nisan',
                    detail: <div className="detail">
                        <h3>Doç.Dr.And YAVUZ</h3>
                        <article>Konu : 1.Trimester Fetal Anomali Taraması</article>
                        <h3>Prof.Dr.Evrim ERDEMOĞLU</h3>
                        <article>Konu : Laparoskopik Histerektomide Püf Noktalar</article>
                    </div>
                },
                {
                    id: 4,
                    name: 'Haziran',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Haziran/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Haziran/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Haziran/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Haziran/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Haziran/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Haziran/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Haziran/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Haziran/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Haziran/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Haziran/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Haziran/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Haziran/12.jpg',
                    ],
                    link: '/product/8/4/haziran',
                    detail: <div className="detail">
                        <h3>Doç.Dr.Volkan TURAN</h3>
                        <article>Konu : İntrauterin İnseminasyon'da Başarılı Olmanın Sırları</article>
                        <h3>Doç.Dr.Şafak OLGAN</h3>
                        <article>Konu : Açıklanamayan İnfertiliteye Güncel Yaklaşım</article>
                    </div>
                },
                {
                    id: 7,
                    name: 'Eylül',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/12.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/13.jpg',
                    ],
                    link: '/product/8/7/eylul',
                    detail: <div className="detail">
                        <h3>Prof.Dr.Serdar GÜNALP</h3>
                        <article>Konu : IUI:Kritik Analiz</article>
                        <h3>Prof.Dr.Esat ORHON</h3>
                        <article>Konu : İnfertilitede Ezber Bozan Realiteler</article>
                    </div>
                },
                {
                    id: 8,
                    name: 'Kasım',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Kasim/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Kasim/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Kasim/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Kasim/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Kasim/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Kasim/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Kasim/7.jpg',
                    ],
                    link: '/product/8/8/kasim',
                    detail: <div className="detail">
                        <h3>Doç.Dr.Kemal ÖZERKAN</h3>
                        <article>Konu : Laparoskopinin trikleri; Görsel sunumlar eşliğinde tartışma</article>
                        <h3>Prof.Dr.Gürkan UNCU</h3>
                        <article>Konu : Biz infertil çifte nasıl yaklaşıyoruz ? Tanı ve tedaviye minimal yaklaşım ?</article>
                    </div>
                },
                {
                    id: 9,
                    name: 'Aralık',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Aralik/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Aralik/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Aralik/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Aralik/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Aralik/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Aralik/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Aralik/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Aralik/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Aralik/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Aralik/10.jpg',
                    ],
                    link: '/product/8/9/aralik',
                    detail: <div className="detail">
                        <h3>Prof.Dr.Mahmut Tuncay ÖZGÜN</h3>
                        <article>Konu : Sezaryen skar gebeliği</article>
                        <h3>Prof.Dr.Selim BÜYÜKKURT</h3>
                        <article>Konu : İyi bir ultrason görüntüsü için cihaz ayarları</article>
                    </div>
                },
            ]
        }
    },
    {
        category: {
            id: 9,
            name: '2019',
            imgsrc: [process.env.PUBLIC_URL + '/images/category/2019.jpeg'],
            content: 'Kuru Kimyevi Tozlu, Karbondioksitli, Köpüklü, Sulu yangın söndürme cihazları.',
            link: '/category/2019/9/subcategories',
            subcategories: [
                {
                    id: 0,
                    name: 'Mart',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/12.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/13.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/14.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/15.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/16.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/17.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/18.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/19.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/20.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/21.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/22.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mart/23.jpg',
                    ],
               
                    content: '',
                    link: '/product/9/0/mart',
                    detail: <div className="detail">
                        <h3>Prof.Dr.Ahmet Zeki IŞIK</h3>
                        <article>Konu : Üremeye yardımcı tedavilerde 26 yılda nereden nereye geldik ?
Sevdalı bir yolculuğun kişisel notları</article>
                        <h3>Doç.Dr.Kubilay VİCDAN</h3>
                        <article>Konu : IVF uygulamalarında sıradışı deneyimler</article>
                        <h3>Tahsin Cüneyt BALIKÇIOĞLU</h3>
                        <article>Konu : Buzuldan denize kıtaların doğası</article>
                    </div>
                },
               
                {
                    id: 2,
                    name: 'Mayıs',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mayıs/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mayıs/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mayıs/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mayıs/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mayıs/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mayıs/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mayıs/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mayıs/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mayıs/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mayıs/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mayıs/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Mayıs/12.jpg',
                    ],
                    link: '/product/9/2/mayıs',
                    detail: <div className="detail">
                        <h3>Prof.Dr.Erkut ATTAR</h3>
                        <article>Konu : Endometrioziste profilaktik tedavi mümkün mü?</article>
                        <h3>Doç.Dr.Mehmet SAKINCI</h3>
                        <article>Konu : Endometriozis Kronik Pelvik Ağrı İlişkisi</article>
                        <h3>Uzm.Fzt.Alime BÜYÜK GÖNEN</h3>
                        <article>Konu : Endometrioziste Pelvik Taban Rehabilitasyonu
</article>
                    </div>
                },
                {
                    id: 3,
                    name: 'Eylül',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Eylul/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Eylul/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Eylul/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Eylul/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Eylul/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Eylul/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Eylul/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Eylul/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Eylul/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Eylul/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Eylul/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Eylul/12.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Eylul/13.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Eylul/14.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Eylul/15.jpg',

                    ],
                    link: '/product/9/3/eylul',
                    detail: <div className="detail">
                        <h3>Psikoterapist Gülsen KARABACAK</h3>
                        <article>Konu : Psikodinamik bakış açısı ile Vaginismusun anlamı</article>
                        <h3>Op.Dr. Dilek ÖCAL</h3>
                        <article>Konu : Jinekolojik temelde Vaginismusun tedavisi</article>
                        <h3>Op.Dr. N.Umut ÖZDEMİR</h3>
                        <article>Konu : Tedavi sürecinde Vaginismus erkeğinin yönetimi</article>
                    </div>
                },
                {
                    id: 4,
                    name: 'Ekim',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Ekim/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Ekim/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Ekim/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Ekim/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Ekim/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Ekim/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Ekim/7.jpg',
                    ],
                    link: '/product/9/4/ekim',
                    detail: <div className="detail">
                        <h3>Doç.Dr. Aysel UYSAL</h3>
                        <article>Konu : Kadın infertilitesinde fito terapi</article>
                        <h3>Prof.Dr. Alim KOŞAR</h3>
                        <article>Konu : Erkek infertilitesinde tamamlayıcı ve bitkisel tedaviler</article>
                    </div>
                },
                {
                    id: 7,
                    name: 'Eylül',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/12.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2018-Eylul/13.jpg',
                    ],
                    link: '/product/8/7/eylul',
                    detail: <div className="detail">
                        <h3>Prof.Dr.Serdar GÜNALP</h3>
                        <article>Konu : IUI:Kritik Analiz</article>
                        <h3>Prof.Dr.Esat ORHON</h3>
                        <article>Konu : İnfertilitede Ezber Bozan Realiteler</article>
                    </div>
                },
                {
                    id: 8,
                    name: 'Kasım',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Kasim/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Kasim/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Kasim/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Kasim/4.jpg',
                      
                    ],
                    link: '/product/9/8/kasim',
                    detail: <div className="detail">
                        <h3>Prof.Dr. Bülent TIRAŞ</h3>
                        <article>Konu : IVF te endometrial sıvının önemi nedir ? Nasıl çözüm bulalım.</article>
                        <h3>Prof.Dr. Hulusi Bülent ZEYNELOĞLU</h3>
                        <article>Konu : Tekrarlayan başarısızlıklarda adjuvan tedaviler</article>
                    </div>
                },
                {
                    id: 9,
                    name: 'Aralık',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Aralik/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Aralik/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Aralik/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Aralik/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Aralik/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Aralik/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Aralik/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2019-Aralik/8.jpg',
     
                    ],
                    link: '/product/9/9/aralik',
                    detail: <div className="detail">
                        <h3>Klinik Psikolog Meltem OK</h3>
                        <article>Konu : Psikodinamik açıdan anorgazmi ve tedavi yöntemleri</article>
                        <h3>Op.Dr. Selcan BAHADIR</h3>
                        <article>Konu : Jineseksolojik açıdan orgazm ve anorgazmi</article>
                    </div>
                },
            ]
        }
    },
    {
        category: {
            id: 10,
            name: '2020',
            imgsrc: [process.env.PUBLIC_URL + '/images/category/2020.jpeg'],
            content: 'Kuru Kimyevi Tozlu, Karbondioksitli, Köpüklü, Sulu yangın söndürme cihazları.',
            link: '/category/2020/10/subcategories',
            subcategories: [
                {
                    id: 0,
                    name: 'Şubat',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2020-Subat/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2020-Subat/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2020-Subat/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2020-Subat/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2020-Subat/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2020-Subat/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2020-Subat/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2020-Subat/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2020-Subat/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2020-Subat/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2020-Subat/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2020-Subat/12.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2020-Subat/13.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2020-Subat/14.jpg',
                    ],
               
                    content: '',
                    link: '/product/10/0/subat',
                    detail: <div className="detail">
                        <h3>Doç.Dr. Şafak OLGAN Psikolog Meltem OK</h3>
                        <article>Konu : Tekrarlayan gebelik kaybı: Klavuzlarda son durum</article>
                        <h3>Doç.Dr. Emre G. PABUÇCU</h3>
                        <article>Konu : Luteal faz desteği: Püf noktalar</article>
                    </div>
                },
               
              
            ]
        }
    },
    {
        category: {
            id: 12,
            name: '2022',
            imgsrc: [process.env.PUBLIC_URL + '/images/category/2022.jpeg'],
            content: 'Kuru Kimyevi Tozlu, Karbondioksitli, Köpüklü, Sulu yangın söndürme cihazları.',
            link: '/category/2022/12/subcategories',
            subcategories: [
                {
                    id: 0,
                    name: 'Nisan',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2022-Nisan/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2022-Nisan/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2022-Nisan/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2022-Nisan/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2022-Nisan/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2022-Nisan/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2022-Nisan/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2022-Nisan/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2022-Nisan/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2022-Nisan/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2022-Nisan/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2022-Nisan/12.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2022-Nisan/13.jpg',
                       
                    ],
               
                    content: '',
                    link: '/product/12/0/nisan',
                    detail: <div className="detail">
                        <h3>Doç. Dr. Sezcan MÜMÜŞOĞLU</h3>
                        <article>Konu : Overyan Stimunasyonun İzlemi,Ultrason ve Hormonlar; neye ne kadar bakmak yeterli?</article>
                        <h3>Doç. Dr. Derman BAŞARAN</h3>
                        <article>Konu : Endometrial Hiperplazin Yönetimi</article>
                    </div>
                },
               
              
            ]
        }
    },
    {
        category: {
            id: 13,
            name: '2023',
            imgsrc: [process.env.PUBLIC_URL + '/images/category/2023.jpeg'],
            content: 'Kuru Kimyevi Tozlu, Karbondioksitli, Köpüklü, Sulu yangın söndürme cihazları.',
            link: '/category/2023/13/subcategories',
            subcategories: [
                {
                    id: 0,
                    name: 'Ocak',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ocak/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ocak/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ocak/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ocak/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ocak/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ocak/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ocak/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ocak/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ocak/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ocak/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ocak/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ocak/12.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ocak/13.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ocak/14.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ocak/15.jpg',
                       
                    ],
               
                    content: '',
                    link: '/product/13/0/ocak',
                    detail: <div className="detail">
                        <h3>Prof. Dr. Somer Cem ATABEKOĞLU</h3>
                        <article>Konu : Beklenmeyen Düşük ve Yüksek Yanıtlı Hastalarda KOH Yönetimi</article>
                        <h3>Prof. Dr. Murat SÖNMEZER</h3>
                        <article>Konu : Fertilite Prezervasyonunda Güncel Durum</article>
                    </div>
                },
                {
                    id: 1,
                    name: 'Şubat',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Subat/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Subat/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Subat/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Subat/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Subat/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Subat/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Subat/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Subat/8.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Subat/9.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Subat/10.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Subat/11.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Subat/12.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Subat/13.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Subat/14.jpg',
                       
                    ],
               
                    content: '',
                    link: '/product/13/1/subat',
                    detail: <div className="detail">
                        <h3>Prof. Dr. Erbil DOĞAN</h3>
                        <article>Konu : Konjenital Uterin Anomaliler ve İnfertilite</article>
                        <h3>Doç. Dr. Sefa KURT</h3>
                        <article>Konu : Anormal Sitolojiye Yaklaşım</article>
                    </div>
                },
                {
                    id: 2,
                    name: 'Nisan',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Nisan/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Nisan/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Nisan/3.png',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Nisan/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Nisan/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Nisan/6.jpg',
                        
                       
                    ],
               
                    content: '',
                    link: '/product/13/2/nisan',
                    detail: <div className="detail">
                        <h3>Prof. Dr. Turan ÇETİN</h3>
                        <article>Konu : Üreme Tedavilerinde Tuba Uterina: Yeterince hakim miyiz?</article>
                        <h3>Prof. Dr. Hasan SERDAROĞLU</h3>
                        <article>Konu : Türkiyede Tıp Eğitimi ve Uygulamada Geçmiş, Güncel Durum ve Olası Hedefler</article>
                    </div>
                },
                {
                    id: 3,
                    name: 'Ekim',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ekim/1.png',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ekim/2.png',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ekim/3.png',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ekim/4.png',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Ekim/5.png',
        
                        
                       
                    ],
               
                    content: '',
                    link: '/product/13/3/ekim',
                    detail: <div className="detail">
                        <h3>Prof. Dr. Alev ÖZER</h3>
                        <article>Konu : Tekrarlayan Vulvovajinit Tedavisi</article>
                        <h3>Doç. Dr. Oya Soylu KARAPINAR</h3>
                        <article>Konu : Deprem ve Kadın Sağlığı</article>
                    </div>
                },
                {
                    id: 4,
                    name: 'Kasım',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Kasim/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Kasim/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Kasim/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Kasim/4.jpg',
                     
        
                        
                       
                    ],
               
                    content: '',
                    link: '/product/13/4/kasim',
                    detail: <div className="detail">
                        <h3>Ahmet Turgay FİŞEKÇİ</h3>
                      
                        <h3>Hakan SAVLI</h3>
                      
                    </div>
                },
                {
                    id: 5,
                    name: 'Aralık',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Aralik/1.jpeg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Aralik/2.jpeg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Aralik/3.jpeg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2023-Aralik/4.jpeg',
                     
        
                        
                       
                    ],
               
                    content: '',
                    link: '/product/13/5/aralik',
                    detail: <div className="detail">
                        <h3>Prof. Dr. Engin ORAL</h3>
                        <article>Konu : Endometrioziste İnfertilite Yönetimi</article>
                        <h3>Prof. Dr. Ümit İNCEBOZ</h3>
                        <article>Konu : Adölesan Endometriozis: Tanıdan Tedaviye</article>
                    </div>
                },
            ]
        }
    },
    {
        category: {
            id: 14,
            name: '2024',
            imgsrc: [process.env.PUBLIC_URL + '/images/category/2024.jpeg'],
            content: 'Kuru Kimyevi Tozlu, Karbondioksitli, Köpüklü, Sulu yangın söndürme cihazları.',
            link: '/category/2024/14/subcategories',
            subcategories: [
                {
                    id: 0,
                    name: 'Şubat',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Subat/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Subat/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Subat/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Subat/4.jpg',
                      
                       
                    ],
               
                    content: '',
                    link: '/product/14/0/subat',
                    detail: <div className="detail">
                        <h3>Prof. Dr. Paşa GÖKTAŞ</h3>
                        <article>Konu : Cinsel Yolla Bulaşan Hastalıklarda PCR Panelleri</article>
                        <h3>Doç. Dr. Mehmet SARIER</h3>
                        <article>Konu : Ürolojik Açıdan HPV ve Akut Üretrit</article>
                    </div>
                },
                {
                    id: 1,
                    name: 'Mart',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Mart/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Mart/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Mart/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Mart/4.jpg',
                      
                       
                    ],
               
                    content: '',
                    link: '/product/14/1/Mart',
                    detail: <div className="detail">
                        <h3>Doç. Dr. And YAVUZ</h3>
                        <article>Konu : Servikal Serklaj : Zamanlama, Endikasyon, Teknik</article>
                        <h3>Op. Dr. Berkan SAYAL</h3>
                        <article>Konu : Preterm Eylem Yönetiminde Tokolitik Tedaviler Ve Antenatal Steroid Uygulamaları</article>
                    </div>
                },
                {
                    id: 2,
                    name: 'Mayıs',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Mayis/1.png',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Mayis/2.png',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Mayis/3.png',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Mayis/4.png',
                      
                       
                    ],
               
                    content: '',
                    link: '/product/14/2/Mayis',
                    detail: <div className="detail">
                        <h3>Dr. Demet BAŞER</h3>
                        <article>Konu : KAHEV ve Burslar</article>
                        <h3>Dr. Esra TAMBURACI</h3>
                        <article>Konu : Menopoza Güncel Yaklaşımlar</article>
                    </div>
                },
                {
                    id: 3,
                    name: 'Aralık',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Aralik/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Aralik/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Aralik/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Aralik/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Aralik/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Aralik/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Aralik/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2024-Aralik/8.jpg',
                       
                    ],
               
                    content: '',
                    link: '/product/14/3/aralik',
                    detail: <div className="detail">
                        <h3>Doç.Dr. Erhan Şimşek</h3>
                        <article>Konu : Sosyal Amaçlı Oosit Dondurma: Kime ve
                        Ne Zaman Önerilmeli?</article>
                        <h3>Doç.Dr. Mehmet Murat Işıkalan</h3>
                        <article>Konu : Multifetal Gebelik Redüksiyonu; Endikasyonlar ve Zamanlama Nasıl Olmalıdır?</article>
                    </div>
                },
            ]
        }
    },
    {
        category: {
            id: 15,
            name: '2025',
            imgsrc: [process.env.PUBLIC_URL + '/images/category/2025.jpg'],
            content: 'Kuru Kimyevi Tozlu, Karbondioksitli, Köpüklü, Sulu yangın söndürme cihazları.',
            link: '/category/2025/15/subcategories',
            subcategories: [
                {
                    id: 0,
                    name: 'Şubat',
                    imgsrc: [
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2025-Subat/1.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2025-Subat/2.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2025-Subat/3.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2025-Subat/4.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2025-Subat/5.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2025-Subat/6.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2025-Subat/7.jpg',
                        process.env.PUBLIC_URL + '/eski/Etkinlikler/2025-Subat/8.jpg',
                       
                    ],
               
                    content: '',
                    link: '/product/15/0/ocak',
                    detail: <div className="detail">
                        <h3>Prof. Dr. Berfu Demir</h3>
                        <article>Konu : Ovülasyon İndüksiyonunda Sıradışı Olguların Yönetimi</article>
                        <h3>Prof. Dr. Murat Sönmezer</h3>
                        <article>Konu : Ovaryen Gençleştirme (Rejuvenation) mümkün müdür?</article>
                    </div>
                },
           
            ]
        }
    },
    // {
    //     category: {
    //         id: 7,
    //         name: '2017',
    //         imgsrc: [process.env.PUBLIC_URL + '/images/category/2017.jpeg'],
    //         content: 'FM 200 ,CO2 gazlı ve pano içi otomatik söndürme sistemleri.',
    //         link: '/category/gazli_sondurme_sistemi/7/subcategories',
    //         subcategories: [
    //             {
    //                 id: 0,
    //                 name: 'FM 200 - FE227 (HFC227ea) GAZLI SÖNDÜRME SİSTEMİ',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/fm_200_fe227_(HFC227ea)_gazli_sondurme_sistemi/image-248.png'],
    //                 content: '',
    //                 link: '/product/7/0/adresli_yangin_alarm_sistemleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>FM200 Gazli Sistem Bilesenleri</figcaption>
    //                         <p>FM 200 kimyasal ajani ile baslica dört bilesen ve bu bilesenlerin aksesuarlarindan olusmaktadir.</p>
    //                         <ul>
    //                             <li><b>FM-200 Bilesenleri :</b> FM-200  gazinin depolandigi silindir, silindir koruyucusu ve FM 200 tahliye Nozülünden olusur.</li>
    //                             <li><b>Kontrol Paneli :</b> Sistemin beynidir, algilama sistemini ve ekipmanlarini görüntülemek, izlemek ve sistemi aktive etmek için kullanilir.</li>
    //                             <li><b>Algilama ve Uyari Elemanlari :</b> Sistemdeki algilama dedektörleri ve aksesuarlari, sesli ve görsel uyari veren sirenler, sistemin göz ve kulak islevini yerine getiren dis ekipmanlaridir.</li>
    //                             <li><b>Tamamlayici Ekipmanlar :</b> Uyari levhalari, hortumlar, baglanti fittingleri, basinç manometreleri veya solenoid valf ve silindir valfini çalistirmak için gerekli   aktuatörden olusmaktadir.</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Sistem Dizaynı Ve Çalışma Şekli</figcaption>
    //                         <p>FM 200,  gazi ile korunacak hacim için NFPA 12 ve TS ISO 14520 standartlarında ve kurallarına göre  gereken gaz miktari hesaplanir, Gaz miktarina uygun FM 200 tüpü seçilir, Çapraz zonlu yangin algilama sitemi ile teçhiz edilen odada gereken noktalara FM-200 bosaltma nozullari yerlestirilir,Duman dedektörlerinin aktive olmasi ile birlikte panelden FM-200 sistemi solenoid vanasina akim gönderilir, Tüm FM 200 gazi en fazla 10 saniye içinde korunan hacme bosaltilir.</p>
    //                         <p>Sistemin çalismasi otomatik veya manuel olarak yapilabilir.

    //                             Gazlı sabit söndürme sistemleri, tesisin nitelik ve ihtiyaçlarına bağlı olarak, uyulması zorunlu olan TS ISO 14520 “Gazlı Söndürme Sistemleri – Fiziksel
    //                             Özellikler ve Sistem Tasarımı” ve TS EN 15004 “Sabit Yangınla Mücadele Sistemleri – Gaz Püskürten Sistemler – Tasarım, montaj ve Bakım” standartlarına uygun olarak tasarlanmaktadır.
    //                         </p>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Kullanildigi Yerler:</figcaption>
    //                         <ul>
    //                             <li>Bilgisayar, server odaları</li>
    //                             <li>Telefon ve telekomünikasyon odaları</li>
    //                             <li>Elektrik dagitim odalari / merkezleri</li>
    //                             <li>Degerli malzeme depolari</li>
    //                             <li>Laboratuarlar</li>
    //                             <li>Banka kasalari, Arşivler, Kütüphaneler</li>
    //                             <li>Müzeler ve sanat galerileri</li>
    //                             <li>Türbinkabinleri v.b.  yerlerde FM 200 gazı güvenle kullanılabilir.</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         {/* <figcaption>A Sınıfı Yangınlar</figcaption> */}
    //                         <p>FM-200, Halon 1301 gazinin kullaniminin yasaklanmasi sonrasinda piyasaya sürülen ve en yaygin kullanimi olan Halon alternatifi bir gazdir.
    //                             Kimyasal ismi Heptafluoropropane dir. FM 200 gazi yanginı  kimyasal reaksiyonlari kirma ve isi enerjisini absorve etme özelligi ile yanginlari söndürmektedir.
    //                             Şayet asagidaki durumlardan en az biri varsa, FM 200 gazli söndürme sistemleri tavsiye edilir:
    //                             Korunacak alanda bulunan malzemenin degeri ya da is devamliligi açisindan önemi, saniyeler içinde harekete geçecek bir yangin sistemi gerektiriyorsa.
    //                             Sprinkler sistemi, korunacak alanda bulunan malzemeye zarar verecekse.
    //                             Korunacak alanda insan bulunmaktaysa.
    //                             Yangin sonrasi temizligi elimine edilmek isteniyorsa.
    //                             Kolay dizayn ve kuruluma ihtiyaç varsa.
    //                             FM200 Gazli Sistem Özellikleri
    //                             Renksizdir, kokusuzdur ve yalıtkan bir söndürme ajanıdır.
    //                             Ozon yok etme kaysayısı sıfırdır, Atmosferik ömrü 31-42 yıldır.
    //                             Fiziksel olarak söndürme yapar.
    //                             Gaz boşalması sonrasi artik birakmaz, temizliğe ihtiyaç yoktur, Gaz atmosfere yayılır.
    //                             Insan bulunan mekanlarda güvenle kullanilabilir.
    //                             En yaygin kullanilan Halon 1301 alternatifidir.
    //                             Ekonomiktir.
    //                             Az yer kaplar.
    //                             Bosalma süresi 10 saniyedir.

    //                         </p>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 1,
    //                 name: 'PANO İÇİ GAZLI OTOMATİK SÖNDÜRME SİSTEMLERİ',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/pano_ici_gazli_otomatik_sondurme_sistemleri/image-250.png'],
    //                 content: '',
    //                 link: '/product/7/1/pano_ici_gazli_otomatik_sondurme_sistemleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>

    //                     <figure>
    //                         <figcaption>Kullanildigi Yerler:</figcaption>
    //                         <ul>
    //                             <li>Elektrik Panoları</li>
    //                             <li>UPS Kabinleri</li>
    //                             <li>Telefon Santralleri Kabinleri</li>
    //                             <li>Zırhlı Araç Motor Bölmeleri</li>
    //                             <li>Hassas Elektronik Cihazlar</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Pano İçi Söndürme Sistemi Nedir ? </figcaption>
    //                         <p>Kendi kendine harekete geçen yangını başladığı yerde bitiren esnek kırmızı algılama hortumu bulunan otomatik bir sistemdir.  Algılama hortumunu en iyi şekilde çalışmak üzere, polimer karışımı maddelerden yapılmıştır.Yangın alanını sayılı yerden değil, algılama hortumunun geçtiği her yerden koruma altına alır. Bu sistem tek başına çalışılabilmesi yanında bir alarm sistemi ile veya bir sistem kapatma şalteri ile de çalışabilir. Bu özellikler tüm riskleri ortadan kaldırır.</p>
    //                         <figcaption>Neden bu kadar çok etkilidir ? </figcaption>
    //                         <p>Çünkü algılama hortumu, korunacak yerin her tarafından geçtiği için çıkan yangını merkez yeri, yani ilk çıktığı yerden algılayıp söndürür. Algılama hortumu nem, pislik ve düzensiz hava akımından etkilenmez. Her koşulda algılama işini yapar. Basınçlandırılmış algılama hortumu, korunan alanın içine yerleştirilir. Yangın anında, yangın kaynağının bulunduğu taraftaki hortum küçük yangını hissedince erir ve patlar. Patlayan yerden söndürme maddesi tahliye olur ve yangını daha küçük alev halinde iken söndürür. İsteğe bağlı olarak adapte edilen alarm sistemi de devreye girer. Sistem çok çeşitli boyutlarda ve uzunlukta kurulabilir.</p>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 2,
    //                 name: 'Co2 GAZLI SÖNDÜRME SİSTEMLERİ',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/co2_gazli_sondurme_sistemleri/image-251.png'],
    //                 content: '',
    //                 link: '/product/7/2/co2_gazli_sondurme_sistemleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>Özellikler</figcaption>
    //                         <ul>
    //                             <li>Ozona zarar vermez.</li>
    //                             <li>Fiziksel olarak söndürme yapar.</li>
    //                             <li>Lokal söndürme amaçli kullanilir</li>
    //                             <li>Insan bulunan mekanlarda kullanilmaz</li>
    //                             <li>Yeniden doldurma maliyeti düsük, yangin yaygin olarak bulunabilir</li>
    //                             <li>Bosalma süresi 60-120 saniyedir. ( lokal sistemler için 30 saniye)</li>
    //                             <li>Düsük basinçli sistemler: Düsük basinçli karbondioksit sistemleri, yüksek miktarda söndürücüye ihtiyaç duyulan mekanlarda toplam hacim koruma uygulamalarinda kullanilir</li>
    //                             <li>Yüksek basinçli sistemler: Yüksek basinçli karbon dioksit sistemleri, hem toplam hacim koruma, hem de lokal koruma uygulamalarinda kullanilmaktadir.</li>

    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Kullanıldığı Yerler</figcaption>
    //                         <ul>
    //                             <li>Güç aktarim odalari</li>
    //                             <li>Jeneratör güç setleri</li>
    //                             <li>Motor odalari</li>
    //                             <li>Kayit odalari</li>
    //                             <li>Kablo ve tesisat galerileri</li>
    //                             <li>Elektrik dagitim odalari/merkezleri</li>
    //                             <li>Bilgisayar odalari</li>
    //                             <li>Yanici sivi depolama alanlari</li>
    //                             <li>Boyama kabinleri</li>
    //                             <li>Arsivler</li>
    //                             <li>Basim Evleri</li>
    //                             <li>Endüstriyel Firinlar</li>
    //                             <li>Genis Ticari Yemek Pisirme Alanlari</li>
    //                             <li>Ve daha birçok end. uygulamalar</li>

    //                         </ul>
    //                         <p>Karbondioksit (CO2) Gazli Söndürme Sisteminin Kullanilamayacagi Malzemeler
    //                             Selüloz nitrat gibi oksijen ihtiva eden kimyasallar, sodium, potasyum, magnezyum, titanyum ve zirkonyum gibi reaktif metaller, metal hidratlar.
    //                         </p>
    //                     </figure>
    //                     <figure>
    //                         {/* <figcaption>Kullanildigi Yerler:</figcaption> */}
    //                         <p>Gazlı söndürme sistemlerinde kullanılan en eski ve en yaygın gazdır. Lokal uygulamalarda kullanılan tek gazdır ve özellikle kimyasalların bulunduğu mahallerde en etkin çözüm olarak kullanılmaktadır. Soğutma etkisinden dolayı narin elektrikli ve elektronik cihazların bulunduğu mahallerde, ayrıca boğma etkisinden dolayı insanlı mahallarde kullanılması sakıncalıdır. CO2 gazı, söndürme yapılan ortamdaki bağıl oksijen yoğunlığunu, yangının sürmesi için gereken oksijen yoğunluğu seviyesinin altına düşürerek yangınları söndürmektedir.</p>
    //                         <p>Karbondioksit; renksiz, kokusuz, elektrik iletkenligi olmayan, yangin söndürmek için uygun bir inert gazdir.</p>
    //                         <p>Karbondioksit hava içerisindeki oksijeni ve/veya gaz fazindaki bir yakitin konsantrasyonunu yanmanin duracagi noktaya kadar düsürerek, yanmayi sogutarak söndürür.</p>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 3,
    //                 name: 'AEROSOL YANGIN SÖNDÜRME SİSTEMLERİ',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/urn1.jpg'],
    //                 content: '',
    //                 link: '/product/7/3/aerosol_yangin_sondurme_sistemleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     {/* <figure>
    //                         <figcaption>Özellikler</figcaption>
    //                         <ul>
    //                             <li>Ozona zarar vermez.</li>
    //                             <li>Fiziksel olarak söndürme yapar.</li>
    //                             <li>Lokal söndürme amaçli kullanilir</li>
    //                             <li>Insan bulunan mekanlarda kullanilmaz</li>
    //                             <li>Yeniden doldurma maliyeti düsük, yangin yaygin olarak bulunabilir</li>
    //                             <li>Bosalma süresi 60-120 saniyedir. ( lokal sistemler için 30 saniye)</li>
    //                             <li>Düsük basinçli sistemler: Düsük basinçli karbondioksit sistemleri, yüksek miktarda söndürücüye ihtiyaç duyulan mekanlarda toplam hacim koruma uygulamalarinda kullanilir</li>
    //                             <li>Yüksek basinçli sistemler: Yüksek basinçli karbon dioksit sistemleri, hem toplam hacim koruma, hem de lokal koruma uygulamalarinda kullanilmaktadir.</li>

    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Kullanıldığı Yerler</figcaption>
    //                         <ul>
    //                             <li>Güç aktarim odalari</li>
    //                             <li>Jeneratör güç setleri</li>
    //                             <li>Motor odalari</li>
    //                             <li>Kayit odalari</li>
    //                             <li>Kablo ve tesisat galerileri</li>
    //                             <li>Elektrik dagitim odalari/merkezleri</li>
    //                             <li>Bilgisayar odalari</li>
    //                             <li>Yanici sivi depolama alanlari</li>
    //                             <li>Boyama kabinleri</li>
    //                             <li>Arsivler</li>
    //                             <li>Basim Evleri</li>
    //                             <li>Endüstriyel Firinlar</li>
    //                             <li>Genis Ticari Yemek Pisirme Alanlari</li>
    //                             <li>Ve daha birçok end. uygulamalar</li>

    //                         </ul>
    //                         <p>Karbondioksit (CO2) Gazli Söndürme Sisteminin Kullanilamayacagi Malzemeler
    //                             Selüloz nitrat gibi oksijen ihtiva eden kimyasallar, sodium, potasyum, magnezyum, titanyum ve zirkonyum gibi reaktif metaller, metal hidratlar.
    //                         </p>
    //                     </figure> */}
    //                     <figure>
    //                         <p>
    //                             DSPA.tr merkezi Hollanda’da bulunan yenilikçi ve lider bir aerosol üreticisi DSPA.nl 'nin Türkiye temsilcisidir. Aerosol söndürücüleri yangını bastırma ve söndürme amaçlı kullanılmaktadır. Ürünlerimiz dünya çapında birçok hükümet tarafından itfaiyelerde ilk müdahale ve bir çok endüstriyel alanda yaygın olarak kullanılmaktadır.

    //                             DSPA kalite, güvenilirlik, hizmet ve yenilikçi ürün geliştirme amacı ile müşterilerine katma değer sağlamak için çalışmaktadır. İştirakciler ve stratejik ortaklarından oluşan bir ağ kullanarak, ürünlerimizi dünyanın her yerine ulaştırmaktayız. DSPA hedeflerinden biri, dünya çapını kapsayan bir dağıtıcı ağı oluşturmaktır. 2015 yılı itibari ile 65 ülkede satış ağını oluşturmuştur.
    //                         </p>
    //                         {/* <figcaption>Kullanildigi Yerler:</figcaption> */}
    //                         {/* <p>Gazlı söndürme sistemlerinde kullanılan en eski ve en yaygın gazdır. Lokal uygulamalarda kullanılan tek gazdır ve özellikle kimyasalların bulunduğu mahallerde en etkin çözüm olarak kullanılmaktadır. Soğutma etkisinden dolayı narin elektrikli ve elektronik cihazların bulunduğu mahallerde, ayrıca boğma etkisinden dolayı insanlı mahallarde kullanılması sakıncalıdır. CO2 gazı, söndürme yapılan ortamdaki bağıl oksijen yoğunlığunu, yangının sürmesi için gereken oksijen yoğunluğu seviyesinin altına düşürerek yangınları söndürmektedir.</p>
    //                         <p>Karbondioksit; renksiz, kokusuz, elektrik iletkenligi olmayan, yangin söndürmek için uygun bir inert gazdir.</p>
    //                         <p>Karbondioksit hava içerisindeki oksijeni ve/veya gaz fazindaki bir yakitin konsantrasyonunu yanmanin duracagi noktaya kadar düsürerek, yanmayi sogutarak söndürür.</p> */}
    //                         <figcaption>DSPA Aerosol Söndürücü sistemlerin avantajları;   </figcaption>
    //                         DSPA Aerosol Söndürücü sistemlerin avantajları;

    //                         Kolayca yangın algılama ve söndürme sistemlerine bağlanabilir.
    //                         Yangının yayılmasını ve dolayısıyla dolaylı maliyetlerin sınırlandırılmasını sağlayarak mümkün olduğunca yangını kaynağına yakın bir koruma sağlar, bir bölme ya da nesnenin içine monte edilebilir.
    //                         En az 15 yıllık bir servis ömrü vardır ve akredite edilmiştir.
    //                         Basınç içermez, basınca neden olmaz, dolayısı ile kolay taşınabilir ve monte edilebilir.
    //                         Aktivasyon tam otomatik ve manuel (bağımsız) olarak yapılabilir.
    //                         Elektrik enerjisi veya güç kaynağı kullanmadan çalışabilir.
    //                         Portatiftir ve yerden tasarruf sağlar.
    //                         Rina Sertifikasyonuna sahiptir. 98%. Ortam sıcaklığı kadar% 98 nem ile -50 ° C ile
    //                         + 75 ° C arasında değişen ortamlarda kullanılabilir.
    //                         Devam eden (üretim) işlemlerinde sistemsel herhangi bir kesinti olmadan, kolay ve hızlı monte edilebilir.
    //                         Diğer geleneksel gazlı ve tozlu sistemlere göre bakım gerektirmez.
    //                         İnsanlara ve hayvanlara zararı yoktur.
    //                         Çevre ve ozon dostu bir üründür. Ozon tüketme potansiyeli (sıfır) yoktur.
    //                         Oksijen seviyelerini tüketmez.
    //                         Temini ve montajı kolaydır. Taşınabilir ve yer değiştirilebilir.
    //                         Zaman ve paradan tasarruf sağlar.
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 4,
    //                 name: 'KÖPÜKLÜ SÖNDÜRME SİSTEMLERİ',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/kopuklu.jpeg'],
    //                 content: '',
    //                 link: '/product/7/4/kopuklu_sondurme_sistemleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     {/* <figure>
    //                         <figcaption>Özellikler</figcaption>
    //                         <ul>
    //                             <li>Ozona zarar vermez.</li>
    //                             <li>Fiziksel olarak söndürme yapar.</li>
    //                             <li>Lokal söndürme amaçli kullanilir</li>
    //                             <li>Insan bulunan mekanlarda kullanilmaz</li>
    //                             <li>Yeniden doldurma maliyeti düsük, yangin yaygin olarak bulunabilir</li>
    //                             <li>Bosalma süresi 60-120 saniyedir. ( lokal sistemler için 30 saniye)</li>
    //                             <li>Düsük basinçli sistemler: Düsük basinçli karbondioksit sistemleri, yüksek miktarda söndürücüye ihtiyaç duyulan mekanlarda toplam hacim koruma uygulamalarinda kullanilir</li>
    //                             <li>Yüksek basinçli sistemler: Yüksek basinçli karbon dioksit sistemleri, hem toplam hacim koruma, hem de lokal koruma uygulamalarinda kullanilmaktadir.</li>

    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Kullanıldığı Yerler</figcaption>
    //                         <ul>
    //                             <li>Güç aktarim odalari</li>
    //                             <li>Jeneratör güç setleri</li>
    //                             <li>Motor odalari</li>
    //                             <li>Kayit odalari</li>
    //                             <li>Kablo ve tesisat galerileri</li>
    //                             <li>Elektrik dagitim odalari/merkezleri</li>
    //                             <li>Bilgisayar odalari</li>
    //                             <li>Yanici sivi depolama alanlari</li>
    //                             <li>Boyama kabinleri</li>
    //                             <li>Arsivler</li>
    //                             <li>Basim Evleri</li>
    //                             <li>Endüstriyel Firinlar</li>
    //                             <li>Genis Ticari Yemek Pisirme Alanlari</li>
    //                             <li>Ve daha birçok end. uygulamalar</li>

    //                         </ul>
    //                         <p>Karbondioksit (CO2) Gazli Söndürme Sisteminin Kullanilamayacagi Malzemeler
    //                             Selüloz nitrat gibi oksijen ihtiva eden kimyasallar, sodium, potasyum, magnezyum, titanyum ve zirkonyum gibi reaktif metaller, metal hidratlar.
    //                         </p>
    //                     </figure> */}
    //                     <figure>
    //                         <p>
    //                             Sentetik bazlı, protein bazlı, film oluşturucu ve alkole dayanıklı gibi çeşitleri bulunan “köpük” bazlı söndürme sistemleri; daha çok yakıt ve yanıcı sıvı yangınlarının söndürülmesinde kullanılmaktadır.

    //                             Manuel yahut otomatik olarak aktive edilebilen sistem çeşitleri vardır. Köpük ajanı, genellikle sprinkler sistemlerinde, yangın dolaplarında, köpük monitörlerinde, köpük jeneratör sistemlerinde kullanılmaktadır.

    //                             Tümü onaylı olan ve tarafımızdan tedarik edilen, montajı ve periyodik bakımları gerçekleştirilen köpük sistemi malzemelerinin tasarımında ise, VDS – Almanya onaylı hidrolik hesap programları kullanılmakta ve müşteriye tüm doneler teslim edilmektedir.
    //                         </p>
    //                         {/* <figcaption>Kullanildigi Yerler:</figcaption> */}
    //                         {/* <p>Gazlı söndürme sistemlerinde kullanılan en eski ve en yaygın gazdır. Lokal uygulamalarda kullanılan tek gazdır ve özellikle kimyasalların bulunduğu mahallerde en etkin çözüm olarak kullanılmaktadır. Soğutma etkisinden dolayı narin elektrikli ve elektronik cihazların bulunduğu mahallerde, ayrıca boğma etkisinden dolayı insanlı mahallarde kullanılması sakıncalıdır. CO2 gazı, söndürme yapılan ortamdaki bağıl oksijen yoğunlığunu, yangının sürmesi için gereken oksijen yoğunluğu seviyesinin altına düşürerek yangınları söndürmektedir.</p>
    //                         <p>Karbondioksit; renksiz, kokusuz, elektrik iletkenligi olmayan, yangin söndürmek için uygun bir inert gazdir.</p>
    //                         <p>Karbondioksit hava içerisindeki oksijeni ve/veya gaz fazindaki bir yakitin konsantrasyonunu yanmanin duracagi noktaya kadar düsürerek, yanmayi sogutarak söndürür.</p> */}
    //                         <figcaption>Başlıca Kullanım Alanları:   </figcaption>
    //                         <ul>
    //                             <li>İlaç, gıda, lastik, baskı, akaryakıt üretim ve depolama tesisleri</li>
    //                             <li>Yanıcı – parlayıcı kimyasal madde üretim tesisleri ve depoları</li>
    //                             <li>Uçak ve helikopter hangarları</li>
    //                             <li>Akaryakıt dolum istasyonları</li>
    //                             <li>Deniz sektörü kargo tankları ve kimyasal madde tankları</li>
    //                             <li>Tersanelerde iskele ve marinalar</li>
    //                             <li>Rafineriler</li>
    //                             <li>Savunma sanayi açık-kapalı depolar ve dolum istasyonları</li>
    //                             <li>Orman yangınları</li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //         ]
    //     }
    // },
    // {
    //     category: {
    //         id: 12,
    //         name: '2018',
    //         imgsrc: [process.env.PUBLIC_URL + '/images/category/2018.jpeg'],
    //         content: 'Sıva altı & sıva üstü acil aydınlatma armatürleri , tek yönlü veya çift yönlü acil yönlendirme(EXİT ) armatürleri.',
    //         link: '/category/acil_aydinlatma_ve_yonlendirme_armaturleri/12/subcategories',
    //         subcategories: [
    //             {
    //                 id: 0,
    //                 name: 'SG002 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg002-cift-yonlu-acil-yonlendirme-armaturu__3774-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/0/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Exit Çıkış Sağ-Sol Ok</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 1,
    //                 name: 'SG003 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg139-tek-yonlu-acil-aydinlatma-armaturu__3817-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/1/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Exit Çıkış Sağ-Sol Ok</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 2,
    //                 name: 'SG004 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg004-cift-yonlu-acil-yonlendirme-armaturu__3772-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/2/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Sağ-Sol Ok</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 3,
    //                 name: 'SG005 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg005-cift-yonlu-acil-yonlendirme-armaturu__3771-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/3/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Exit Çıkış Aşağı Ok</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 5,
    //                 name: 'SG007 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg007-cift-yonlu-acil-yonlendirme-armaturu__3767-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/5/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Exit Çıkış Aşağı Ok</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 6,
    //                 name: 'SG010 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg010-cift-yonlu-acil-yonlendirme-armaturu__3766-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/6/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Bay WC</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 7,
    //                 name: 'SG011 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg011-cift-yonlu-acil-yonlendirme-armaturu__3765-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/7/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Bayan WC</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 8,
    //                 name: 'SG012 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/fotomat-sg012-isikli-wc-yonlendirme-tabelasi-levhasi__1484630227065435.webp'],
    //                 content: '',
    //                 link: '/product/12/8/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Bayan Bay WC</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 28,
    //                 name: 'SG125 Tek Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg125-tek-yonlu-acil-yonlendirme-armaturu__3770-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/28/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Yeşil led ampüllü şarjlı</li>
    //                             <li>Tek yönlü</li>
    //                             <li>Sürekli yanma, enerji kesintisinde 3 saat çalışma özelliği</li>
    //                             <li>Sol yön</li>
    //                             <li>Voltaj:220V AC</li>
    //                             <li>Parlaklık: 56-130 cd/m2</li>
    //                             <li>Watt:3W</li>
    //                             <li>IP:20</li>
    //                             <li>Koli adeti:10</li>
    //                             <li>Sertifika:CE</li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 29,
    //                 name: 'SG126 Tek Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg126-tek-yonlu-acil-aydinlatma-armaturu__3815-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/29/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Yeşil led ampüllü şarjlı</li>
    //                             <li>Tek yönlü</li>
    //                             <li>Sürekli yanma, enerji kesintisinde 3 saat çalışma özelliği</li>
    //                             <li>Sağ yön</li>
    //                             <li>Voltaj:220V AC</li>
    //                             <li>Parlaklık: 56-130 cd/m2</li>
    //                             <li>Watt:3W</li>
    //                             <li>IP:20</li>
    //                             <li>Koli adeti:10</li>
    //                             <li>Sertifika:CE</li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 30,
    //                 name: 'SG127 Tek Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg127-tek-yonlu-acil-aydinlatma-armaturu__4934-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/30/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Yeşil led ampüllü şarjlı</li>
    //                             <li>Tek yönlü</li>
    //                             <li>Sürekli yanma, enerji kesintisinde 3 saat çalışma özelliği</li>
    //                             <li>Aşağı yön </li>
    //                             <li>Voltaj:220V AC</li>
    //                             <li>Parlaklık: 56-130 cd/m2</li>
    //                             <li>Watt:3W</li>
    //                             <li>IP:20</li>
    //                             <li>Koli adeti:10</li>
    //                             <li>Sertifika:CE</li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 31,
    //                 name: 'SG128 Tek Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg128-tek-yonlu-acil-aydinlatma-armaturu__3816-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/31/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Yeşil led ampüllü şarjlı</li>
    //                             <li>Tek yönlü</li>
    //                             <li>Sürekli yanma, enerji kesintisinde 3 saat çalışma özelliği</li>
    //                             <li>Yukarı yön</li>
    //                             <li>Voltaj:220V AC</li>
    //                             <li>Parlaklık: 56-130 cd/m2</li>
    //                             <li>Watt:3W</li>
    //                             <li>IP:20</li>
    //                             <li>Koli adeti:10</li>
    //                             <li>Sertifika:CE</li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 32,
    //                 name: 'SG139 Tek Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg139-tek-yonlu-acil-aydinlatma-armaturu__3817-1 (1).jpg'],
    //                 content: '',
    //                 link: '/product/12/32/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Yeşil led ampüllü şarjlı</li>
    //                             <li>Tek yönlü</li>
    //                             <li>Sürekli yanma, enerji kesintisinde 3 saat çalışma özelliği</li>
    //                             <li>Exit çıkış yön</li>
    //                             <li>Voltaj:220V AC</li>
    //                             <li>Parlaklık: 56-130 cd/m2</li>
    //                             <li>Watt:3W</li>
    //                             <li>IP:20</li>
    //                             <li>Koli adeti:10</li>
    //                             <li>Sertifika:CE</li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 9,
    //                 name: 'SG155 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg155-cift-yonlu-acil-yonlendirme-armaturu__3763-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/9/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Merdivenli Sağ-Sol Ok</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 10,
    //                 name: 'SG156 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg156-cift-yonlu-acil-yonlendirme-armaturu__3762-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/10/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Merdivenli Aşağı Ok</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 11,
    //                 name: 'SG157 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg157-cift-yonlu-acil-yonlendirme-armaturu__3761-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/11/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Merdivenli Yukarı Ok</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 12,
    //                 name: 'SG013 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg013-cift-yonlu-acil-yonlendirme-armaturu__3760-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/12/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Sağ-Sol Ok</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 13,
    //                 name: 'SG014 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg014-cift-yonlu-acil-yonlendirme-armaturu__3759-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/13/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Aşağı Ok</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 14,
    //                 name: 'SG015 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg015-cift-yonlu-acil-yonlendirme-armaturu__3758-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/14/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Yukarı Ok</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 15,
    //                 name: 'SG016 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg016-cift-yonlu-acil-yonlendirme-armaturu__3757-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/15/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Exit</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 16,
    //                 name: 'SG017 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg017-cift-yonlu-acil-yonlendirme-armaturu__3756-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/16/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Çıkış</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 17,
    //                 name: 'SG018 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg018-cift-yonlu-acil-yonlendirme-armaturu__3809-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/17/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Acil Çıkış </li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 18,
    //                 name: 'SG019 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg019-cift-yonlu-acil-yonlendirme-armaturu__3810-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/18/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Merdivenli Yukarı Ok</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 19,
    //                 name: 'SG020 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg020-cift-yonlu-acil-yonlendirme-armaturu__3811-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/19/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Merdivenli Aşağı Ok</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 20,
    //                 name: 'SG021 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg021-cift-yonlu-acil-yonlendirme-armaturu__3812-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/20/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Merdivenli Sağ-Sol Ok</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 21,
    //                 name: 'SG022 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg022-cift-yonlu-acil-yonlendirme-armaturu__3813-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/21/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Sağ-Sol Ok</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 22,
    //                 name: 'SG023 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg023-cift-yonlu-acil-yonlendirme-armaturu__4953-1 (1).jpg'],
    //                 content: '',
    //                 link: '/product/12/22/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Acil Çıkış Sağ-Sol Ok</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 23,
    //                 name: 'SG024 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg024-cift-yonlu-acil-yonlendirme-armaturu__4954-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/23/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Acil Çıkış Aşağı Ok</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 24,
    //                 name: 'SG025 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg025-cift-yonlu-acil-yonlendirme-armaturu__4955-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/24/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Acil Çıkış Yukarı Ok</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 25,
    //                 name: 'SG160 Çift Yönlü Acil Yönlendirme Armatürü',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/sg160-cift-yonlu-acil-yonlendirme-armaturu__3814-1.jpg'],
    //                 content: '',
    //                 link: '/product/12/25/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ GENEL ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>Elektrikler kesildiğinde otomatik devreye girer 3 saat çalışma süresi vardır.</li>
    //                             <li>Kullanım alanları: iş yerleri, imalathaneler, apartmanlar, fabrika ve tesisler, hastahaneler, alışveriş merkezleri, okullar, devlet daireleri.</li>
    //                             <li>Asma halkaları ile tavana monte edilir. </li>
    //                             <li>Elektriğin devre dışı kaldığı durumlarda yaydığı ışıkla karanlıkta yolunuzu bulmanıza yardımcı olur.</li>
    //                             <li>Ürünlerde 6 LED mevcut olup, 1.2 Volt şarj edilebilir pil ürünlere dahildir.</li>
    //                             <li>Çift yönlüdür.</li>
    //                             <li>Ebatlar: 14cm Yükseklik x 35 cm En x 2,5 cm Derinlik</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>ACİL YÖNLENDİRME ARMATÜRLERİ TEKNİK ÖZELLİKLERİ</figcaption>
    //                         <ul>
    //                             <li>6 x LED - Yeşil LED ampül şarjlı</li>
    //                             <li>Çift Yönlü</li>
    //                             <li>Yangın Çıkış</li>
    //                             <li>Sürekli Yanma, Enerji Kesintisinde Çalışma Süresi 3 Saat</li>
    //                             <li>Güç Tüketimi : 3W </li>
    //                             <li>Besleme Gerilimi : 220V AC</li>
    //                             <li>IP20</li>
    //                             <li>Alüminyum Kasa, Cam Yüzey</li>
    //                             <li>Güç/Şarj/Hata Göstergeli</li>
    //                             <li>Test Butonu</li>
    //                             <li>1,2V 600mA Şarjedilebilir Ni-Cd Pil</li>
    //                             <li>Parlaklık:56-130 cd/m2</li>
    //                             <li>Sertifikalar: CE </li>
    //                             <li>Koli Adeti:20 </li>
    //                         </ul>
    //                     </figure>

    //                 </div>
    //             },
    //             {
    //                 id: 26,
    //                 name: 'Versalite Acil Aydınlatma Armatürü',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/image-271.png',
    //                     process.env.PUBLIC_URL + '/images/products/acil_aydinlatma_ve_yonlendirme_armaturleri/image-268.png'
    //                 ],
    //                 content: '',
    //                 link: '/product/12/26/acil_aydinlatma_ve_yonlendirme_armaturleri',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Detaylar</h2>
    //                     <figure>
    //                         <p>Versalite acil aydınlatma armatürü, sabit ve seyyar olarak kullanılabilir. Uzun koridorlarda,
    //                             merdiven boşluklarında, otopark ve fabrikalar gibi geniş kullanım alanlarında uygun
    //                             aydınlatma sağlar. Spot aydınlatmalarda armatür sadece elektrik kesintisinde devreye
    //                             girer. Cihaz üzerinde şebeke geriliminin varlığını gösteren, akünün şarj durumunu
    //                             gösteren LED bulunur. Tüm modellerde bakım gerektirmeyen kuru tip akü kullanılır.
    //                             Şebeke besleme gerilimi standart 220 volt AC, +/- %8 dr. Bataryalı modellerde ilk
    //                             kullanımda şarj süresi en az 24 saattir.
    //                         </p>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Armatürler</figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Yanma Durumu</th>
    //                                     <th>Kullanım Şekli</th>
    //                                     <th>Lamba</th>
    //                                     <th>Akü</th>
    //                                     <th>Süre</th>
    //                                     <th>Boyutlar (En-Boy-Yükseklik)</th>
    //                                     <th>Ağırlık</th>
    //                                     <th>Ip Sınıfı</th>
    //                                     <th>İzolasyon Sınıfı</th>
    //                                     <th>Çalışma Sıcaklığı</th>
    //                                     <th>Güç Tüketimi</th>
    //                                     <th>Çalışma Voltajı</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>VSL-201-18</td>
    //                                     <td>Kesintide Yanan</td>
    //                                     <td>Duvara Montaj</td>
    //                                     <td>2x18 LED</td>
    //                                     <td>12V/1,2AH</td>
    //                                     <td>Kesintide 60</td>
    //                                     <td>95x352x300mm</td>
    //                                     <td>3,8 kg</td>
    //                                     <td>40</td>
    //                                     <td>I</td>
    //                                     <td>-16/+45C</td>
    //                                     <td>3,5w</td>
    //                                     <td>220-240</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>VSL-203-18</td>
    //                                     <td>Kesintide Yanan</td>
    //                                     <td>Duvara Montaj</td>
    //                                     <td>2x18 LED</td>
    //                                     <td>12V/2,3AH</td>
    //                                     <td>Kesintide 180</td>
    //                                     <td>95x352x300mm</td>
    //                                     <td>4,2 kg</td>
    //                                     <td>40</td>
    //                                     <td>I</td>
    //                                     <td>-16/+45C</td>
    //                                     <td>3,5w</td>
    //                                     <td>220-240</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>VSL-201-36</td>
    //                                     <td>Kesintide Yanan</td>
    //                                     <td>Duvara Montaj</td>
    //                                     <td>2x36 LED</td>
    //                                     <td>12V/1,2AH</td>
    //                                     <td>Kesintide 60</td>
    //                                     <td>95x352x300mm</td>
    //                                     <td>4,2 kg</td>
    //                                     <td>40</td>
    //                                     <td>I</td>
    //                                     <td>-16/+45C</td>
    //                                     <td>3,5w</td>
    //                                     <td>220-240</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>VSL-203-36</td>
    //                                     <td>Kesintide Yanan</td>
    //                                     <td>Duvara Montaj</td>
    //                                     <td>2x36 LED</td>
    //                                     <td>12V/4,5AH</td>
    //                                     <td>Kesintide 180</td>
    //                                     <td>95x352x300mm</td>
    //                                     <td>4,75 kg</td>
    //                                     <td>40</td>
    //                                     <td>I</td>
    //                                     <td>-16/+45C</td>
    //                                     <td>3,5w</td>
    //                                     <td>220-240</td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Yanma Durumu</th>
    //                                     <th>Kullanım Şekli</th>
    //                                     <th>Lamba</th>
    //                                     <th>Işıklı Yüzey</th>
    //                                     <th>Süre</th>
    //                                     <th>Boyutlar (En-Boy-Yükseklik)</th>
    //                                     <th>Ağırlık</th>
    //                                     <th>Ip Sınıfı</th>
    //                                     <th>İzolasyon Sınıfı</th>
    //                                     <th>Çalışma Sıcaklığı</th>
    //                                     <th>Güç Tüketimi</th>
    //                                     <th>Çalışma Voltajı</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>VS-2201</td>
    //                                     <td>Kesintide Yanan</td>
    //                                     <td>Duvara Montaj</td>
    //                                     <td>2x20 wat</td>
    //                                     <td>Hareketli</td>
    //                                     <td>Kesintide 60</td>
    //                                     <td>110x370x412mm</td>
    //                                     <td>7,45 kg</td>
    //                                     <td>40</td>
    //                                     <td>I</td>
    //                                     <td>-16/+45C</td>
    //                                     <td>10w</td>
    //                                     <td>220-240</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>VS-2202</td>
    //                                     <td>Kesintide Yanan</td>
    //                                     <td>Duvara Montaj</td>
    //                                     <td>2x20 wat</td>
    //                                     <td>Hareketli</td>
    //                                     <td>Kesintide 120</td>
    //                                     <td>110x370x412mm</td>
    //                                     <td>7,45 kg</td>
    //                                     <td>40</td>
    //                                     <td>I</td>
    //                                     <td>-16/+45C</td>
    //                                     <td>10w</td>
    //                                     <td>220-240</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>VS-2203</td>
    //                                     <td>Kesintide Yanan</td>
    //                                     <td>Duvara Montaj</td>
    //                                     <td>2x20 wat</td>
    //                                     <td>Hareketli</td>
    //                                     <td>Kesintide 180</td>
    //                                     <td>110x370x412mm</td>
    //                                     <td>9 kg</td>
    //                                     <td>40</td>
    //                                     <td>I</td>
    //                                     <td>-16/+45C</td>
    //                                     <td>10w</td>
    //                                     <td>220-240</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>VS-2351</td>
    //                                     <td>Kesintide Yanan</td>
    //                                     <td>Duvara Montaj</td>
    //                                     <td>2x35 wat</td>
    //                                     <td>Hareketli</td>
    //                                     <td>Kesintide 60</td>
    //                                     <td>110x370x412mm</td>
    //                                     <td>7,45 kg</td>
    //                                     <td>40</td>
    //                                     <td>I</td>
    //                                     <td>-16/+45C</td>
    //                                     <td>10w</td>
    //                                     <td>220-240</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>VS-2352</td>
    //                                     <td>Kesintide Yanan</td>
    //                                     <td>Duvara Montaj</td>
    //                                     <td>2x35 wat</td>
    //                                     <td>Hareketli</td>
    //                                     <td>Kesintide 120</td>
    //                                     <td>110x370x412mm</td>
    //                                     <td>9 kg</td>
    //                                     <td>40</td>
    //                                     <td>I</td>
    //                                     <td>-16/+45C</td>
    //                                     <td>10w</td>
    //                                     <td>220-240</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>VS-2353</td>
    //                                     <td>Kesintide Yanan</td>
    //                                     <td>Duvara Montaj</td>
    //                                     <td>2x35 wat</td>
    //                                     <td>Hareketli</td>
    //                                     <td>Kesintide 180</td>
    //                                     <td>180x370x412mm</td>
    //                                     <td>14 kg</td>
    //                                     <td>40</td>
    //                                     <td>I</td>
    //                                     <td>-16/+45C</td>
    //                                     <td>50w</td>
    //                                     <td>220-240</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>VS-2501</td>
    //                                     <td>Kesintide Yanan</td>
    //                                     <td>Duvara Montaj</td>
    //                                     <td>2x50 wat</td>
    //                                     <td>Hareketli</td>
    //                                     <td>Kesintide 60</td>
    //                                     <td>110x370x412mm</td>
    //                                     <td>9 kg</td>
    //                                     <td>40</td>
    //                                     <td>I</td>
    //                                     <td>-16/+45C</td>
    //                                     <td>10w</td>
    //                                     <td>220-240</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>VS-2501.5</td>
    //                                     <td>Kesintide Yanan</td>
    //                                     <td>Duvara Montaj</td>
    //                                     <td>2x50 wat</td>
    //                                     <td>Hareketli</td>
    //                                     <td>Kesintide 90</td>
    //                                     <td>110x370x412mm</td>
    //                                     <td>11,8 kg</td>
    //                                     <td>40</td>
    //                                     <td>I</td>
    //                                     <td>-16/+45C</td>
    //                                     <td>10w</td>
    //                                     <td>220-240</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>VS-2503</td>
    //                                     <td>Kesintide Yanan</td>
    //                                     <td>Duvara Montaj</td>
    //                                     <td>2x50 wat</td>
    //                                     <td>Hareketli</td>
    //                                     <td>Kesintide 180</td>
    //                                     <td>180x370x412mm</td>
    //                                     <td>15 kg</td>
    //                                     <td>40</td>
    //                                     <td>I</td>
    //                                     <td>-16/+45C</td>
    //                                     <td>50w</td>
    //                                     <td>220-240</td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //         ]
    //     }
    // },
    // {
    //     category: {
    //         id: 0,
    //         name: '2019',
    //         imgsrc: [process.env.PUBLIC_URL + '/images/category/2019.jpeg'],
    //         content: 'EN 671-1 ve EN 671-2 Standardına uygun ve belgeli , CE ve İSO belgeli, çeşitli modellerde bina içi ve bina dışı kullanıma uygun, yarı sert veya yassı hortumlu yangın dolapları.',
    //         link: '/category/yangindolaplari/0/subcategories',
    //         subcategories: [
    //             {
    //                 id: 0,
    //                 name: 'DEKORATİF CAM KAPAKLI YANGIN DOLAPLARI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/dekoratif_cam_kapakli_yangin_dolaplari/image-041.png',
    //                     process.env.PUBLIC_URL + '/images/products/dekoratif_cam_kapakli_yangin_dolaplari/image-042.png',
    //                     process.env.PUBLIC_URL + '/images/products/dekoratif_cam_kapakli_yangin_dolaplari/image-043.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/0/0/dekoratif_cam_kapakli_yangin_dolaplari',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>

    //                     <figure>
    //                         <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>10mm orfice</li>
    //                             <li>Ayarlanabilir (jet, spray, shut-off)</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
    //                             <li>12 ATM çalışma basıncı, </li>
    //                             <li>36 ATM patlama basıncı. </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>2" köşe vanalı</li>
    //                             <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Makara</figcaption>
    //                         <ul>
    //                             <li>Özel profilli çelik sactan mamül</li>
    //                             <li>Elektrostatik toz boyalı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Kabinet</figcaption>
    //                         <ul>
    //                             <li>1,20mm DKP sacdan mamül</li>
    //                             <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
    //                             <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
    //                             <li>Alttan veya üstten / sağdan veya soldan tesisat girişine uygun</li>
    //                             <li>Tamamı 4mm temper cam kapaklı</li>
    //                             <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
    //                             <li>Özel mıknatıslı kilit sistemli, demonte kapaklı</li>
    //                             <li>TS 862 EN 3-7 e uygun ,TSE ve CE belgeli 6 kg kuru
    //                                 kimyevi tozlu yangın söndürme cihazı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>DEKORATİF CAM KAPAKLI YANGIN DOLAPLARI</figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Hortum</th>
    //                                     <th>Ölçüler</th>
    //                                     <th>Kapak</th>
    //                                     <th>Model</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>DT_001_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>750x900x220 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_002_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>750x900x220 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_003_YD</td>
    //                                     <td>30 mt.</td>
    //                                     <td>750x900x220 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_004_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>750x900x220 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_005_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>750x900x220 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_006_YD</td>
    //                                     <td>30 mt.</td>
    //                                     <td>750x900x220 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 1,
    //                 name: 'TÜP BÖLMELİ YANGIN DOLAPLARI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/tup_bolmeli_yangin_dolaplari/image-057.png',
    //                     process.env.PUBLIC_URL + '/images/products/tup_bolmeli_yangin_dolaplari/image-058.png',
    //                     process.env.PUBLIC_URL + '/images/products/tup_bolmeli_yangin_dolaplari/image-051.png',
    //                     process.env.PUBLIC_URL + '/images/products/tup_bolmeli_yangin_dolaplari/image-052.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/0/1/tup_bolmeli_yangin_dolaplari',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>10mm orfice</li>
    //                             <li>Ayarlanabilir (jet, spray, shut-off)</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
    //                             <li>12 ATM çalışma basıncı, </li>
    //                             <li>36 ATM patlama basıncı. </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>2" köşe vanalı</li>
    //                             <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Makara</figcaption>
    //                         <ul>
    //                             <li>Özel profilli çelik sactan mamül</li>
    //                             <li>Elektrostatik toz boyalı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Kabinet</figcaption>
    //                         <ul>
    //                             <li>1,20mm DKP sacdan mamül</li>
    //                             <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
    //                             <li>Üstten / yandan tesisat girişine uygun</li>
    //                             <li>Makara ve tüp bölmesi 4 mm Temperli cam kapaklı</li>
    //                             <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
    //                             <li>Özel kilit sistemli, Demonte kapaklı</li>
    //                             <li>TS 862 EN 3-7 e uygun ,TSE ve CE belgeli 6 kg kuru
    //                                 kimyevi tozlu yangın söndürme cihazı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>SIVA ALTI TÜP BÖLMELİ YANGIN DOLAPLARI</figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Hortum</th>
    //                                     <th>Ölçüler</th>
    //                                     <th>Kapak</th>
    //                                     <th>Model</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>DT_007_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>980x710x220 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_008_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>980x710x220 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_009_YD</td>
    //                                     <td>30 mt.</td>
    //                                     <td>980x710x220 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>

    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 2,
    //                 name: 'SIVA ALTI SAC KAPAKLI TÜP BÖLMELİ YANGIN DOLAPLARI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/siva_alti_tup_bolmeli_yangin_dolaplari/image-061.png',
    //                     process.env.PUBLIC_URL + '/images/products/siva_alti_tup_bolmeli_yangin_dolaplari/image-060.png',
    //                     process.env.PUBLIC_URL + '/images/products/siva_alti_tup_bolmeli_yangin_dolaplari/image-059.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/0/2/sıva_alti_sac_kapakli_tup_bolmeli_yangin_dolaplari',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>10mm orfice</li>
    //                             <li>Ayarlanabilir (jet, spray, shut-off)</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
    //                             <li>12 ATM çalışma basıncı, </li>
    //                             <li>36 ATM patlama basıncı. </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>2" köşe vanalı</li>
    //                             <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Makara</figcaption>
    //                         <ul>
    //                             <li>Özel profilli çelik sactan mamül</li>
    //                             <li>Elektrostatik toz boyalı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Kabinet</figcaption>
    //                         <ul>
    //                             <li>1,20mm DKP sacdan mamül</li>
    //                             <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
    //                             <li>Üstten / yandan tesisat girişine uygun</li>
    //                             <li>Makara tarafı sac/tüp bölmesi tarafı isteğe göre 4 mm temperli cam kapaklı</li>
    //                             <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
    //                             <li>Özel kilit sistemli, Demonte kapaklı</li>
    //                             <li>TS 862 EN 3-7 e uygun ,TSE ve CE belgeli 6 kg kuru
    //                                 kimyevi tozlu yangın söndürme cihazı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>SIVA ALTI SAC KAPAKLI TÜP BÖLMELİ YANGIN DOLAPLARI</figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Hortum</th>
    //                                     <th>Ölçüler</th>
    //                                     <th>Kapak</th>
    //                                     <th>Model</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>DT_010_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>980x710x220 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_011_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>980x710x220 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_012_YD</td>
    //                                     <td>30 mt.</td>
    //                                     <td>980x710x220 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>

    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 3,
    //                 name: 'SIVA ÜSTÜ TÜP BÖLMELİ YANGIN DOLAPLARI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/siva_ustu_tup_bolmeli/image-068.png',
    //                     process.env.PUBLIC_URL + '/images/products/siva_ustu_tup_bolmeli/image-067.png',
    //                     process.env.PUBLIC_URL + '/images/products/siva_ustu_tup_bolmeli/image-069.png',
    //                     process.env.PUBLIC_URL + '/images/products/siva_ustu_tup_bolmeli/image-070.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/0/3/sıva_usut_tup_bolmeli_yangin_dolaplari',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>10mm orfice</li>
    //                             <li>Ayarlanabilir (jet, spray, shut-off)</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
    //                             <li>12 ATM çalışma basıncı, </li>
    //                             <li>36 ATM patlama basıncı. </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>2" köşe vanalı</li>
    //                             <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Makara</figcaption>
    //                         <ul>
    //                             <li>Özel profilli çelik sactan mamül</li>
    //                             <li>Elektrostatik toz boyalı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Kabinet</figcaption>
    //                         <ul>
    //                             <li>1,20mm DKP sacdan mamül</li>
    //                             <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
    //                             <li>Üstten / yandan tesisat girişine uygun</li>
    //                             <li>Komple sac veya cam kapaklı</li>
    //                             <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
    //                             <li>Özel kilit sistemli, Demonte kapaklı</li>
    //                             <li>TS 862 EN 3-7 e uygun ,TSE ve CE belgeli 6 kg kuru
    //                                 kimyevi tozlu yangın söndürme cihazı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>SIVA ÜSTÜ TÜP BÖLMELİ YANGIN DOLAPLARI</figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Hortum</th>
    //                                     <th>Ölçüler</th>
    //                                     <th>Kapak</th>
    //                                     <th>Model</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>DT_013_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>1020x750x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_014_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>1020x750x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_015_YD</td>
    //                                     <td>30 mt.</td>
    //                                     <td>1020x750x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_016_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>1020x750x240 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_017_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>1020x750x240 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_018_YD</td>
    //                                     <td>35 mt.</td>
    //                                     <td>1020x750x220 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 4,
    //                 name: 'TÜP ALTTA YATAY CAMLI TEK KAPAKLI YANGIN DOLAPLARI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/tup_altta_yatay_camli_tek_kapakli_yangin_dolabi/image-078.png',
    //                     process.env.PUBLIC_URL + '/images/products/tup_altta_yatay_camli_tek_kapakli_yangin_dolabi/image-077.png',
    //                     process.env.PUBLIC_URL + '/images/products/tup_altta_yatay_camli_tek_kapakli_yangin_dolabi/image-086.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/0/4/tup_altta_yatay_camli_tek_kapakli_yangin_dolabi',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>10mm orfice</li>
    //                             <li>Ayarlanabilir (jet, spray, shut-off)</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
    //                             <li>12 ATM çalışma basıncı, </li>
    //                             <li>36 ATM patlama basıncı. </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>2" köşe vanalı</li>
    //                             <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Makara</figcaption>
    //                         <ul>
    //                             <li>Özel profilli çelik sactan mamül</li>
    //                             <li>Elektrostatik toz boyalı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Kabinet</figcaption>
    //                         <ul>
    //                             <li>1,20mm DKP sacdan mamül</li>
    //                             <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
    //                             <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
    //                             <li>Alttan veya üstten / sağdan veya soldan tesisat girişine uygun</li>
    //                             <li>Komple 4mm temperli cam kapaklı</li>
    //                             <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
    //                             <li>Özel kilit sistemli, Demonte kapaklı</li>
    //                             <li>TS 862 EN 3-7 e uygun ,TSE ve CE belgeli 6 kg kuru
    //                                 kimyevi tozlu yangın söndürme cihazı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>CAMLI TEK KAPAKLI TÜPLÜ YANGIN DOLAPLARI</figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Hortum</th>
    //                                     <th>Ölçüler</th>
    //                                     <th>Kapak</th>
    //                                     <th>Model</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>DT_019_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>750x900x240 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_020_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>750x900x240 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_021_YD</td>
    //                                     <td>30 mt.</td>
    //                                     <td>750x900x240 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_022_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>710x900x220 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_023_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>710x900x220 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_024_YD</td>
    //                                     <td>35 mt.</td>
    //                                     <td>710x900x220 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 5,
    //                 name: 'TÜP ALTTA YATAY SAC TEK KAPAKLI YANGIN DOLAPLARI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/tup_altta_yatay_tek_kapakli_yangin_dolabi/image-095.png',
    //                     process.env.PUBLIC_URL + '/images/products/tup_altta_yatay_tek_kapakli_yangin_dolabi/image-088.png',
    //                     process.env.PUBLIC_URL + '/images/products/tup_altta_yatay_tek_kapakli_yangin_dolabi/image-096.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/0/5/tup_altta_yatay_sac_tek_kapakli_yangin_dolabi',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>10mm orfice</li>
    //                             <li>Ayarlanabilir (jet, spray, shut-off)</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
    //                             <li>12 ATM çalışma basıncı, </li>
    //                             <li>36 ATM patlama basıncı. </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>2" köşe vanalı</li>
    //                             <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Makara</figcaption>
    //                         <ul>
    //                             <li>Özel profilli çelik sactan mamül</li>
    //                             <li>Elektrostatik toz boyalı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Kabinet</figcaption>
    //                         <ul>
    //                             <li>1,20mm DKP sacdan mamül</li>
    //                             <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
    //                             <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
    //                             <li>Alttan veya üstten / sağdan veya soldan tesisat girişine uygun</li>
    //                             <li>Komple sac kapaklı</li>
    //                             <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
    //                             <li>Özel kilit sistemli, Demonte kapaklı</li>
    //                             <li>TS 862 EN 3-7 e uygun ,TSE ve CE belgeli 6 kg kuru
    //                                 kimyevi tozlu yangın söndürme cihazı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>KOMPLE SAC TEK KAPAKLI YANGIN DOLAPLARI</figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Hortum</th>
    //                                     <th>Ölçüler</th>
    //                                     <th>Kapak</th>
    //                                     <th>Model</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>DT_025_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>750x900x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_026_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>750x900x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_027_YD</td>
    //                                     <td>30 mt.</td>
    //                                     <td>750x900x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_028_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>710x900x220 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_029_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>710x900x220 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_030_YD</td>
    //                                     <td>35 mt.</td>
    //                                     <td>710x900x220 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 6,
    //                 name: 'TÜP YANDA DİKEY SIVA ALTI TEK KAPAKLI YANGIN DOLAPLARI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/tüp_yanda_dikey_tek_kapakli_yangin_dolaplari/image-098.png',
    //                     process.env.PUBLIC_URL + '/images/products/tüp_yanda_dikey_tek_kapakli_yangin_dolaplari/image-097.png',
    //                     process.env.PUBLIC_URL + '/images/products/tüp_yanda_dikey_tek_kapakli_yangin_dolaplari/image-099.png',
    //                     process.env.PUBLIC_URL + '/images/products/tüp_yanda_dikey_tek_kapakli_yangin_dolaplari/image-100.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/0/6/tüp_yanda_dikey_siva_alti_tek_kapakli_yangin_dolaplari',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>10mm orfice</li>
    //                             <li>Ayarlanabilir (jet, spray, shut-off)</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
    //                             <li>12 ATM çalışma basıncı, </li>
    //                             <li>36 ATM patlama basıncı. </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>2" köşe vanalı</li>
    //                             <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Makara</figcaption>
    //                         <ul>
    //                             <li>Özel profilli çelik sactan mamül</li>
    //                             <li>Elektrostatik toz boyalı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Kabinet</figcaption>
    //                         <ul>
    //                             <li>1,20mm DKP sacdan mamül</li>
    //                             <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
    //                             <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
    //                             <li>Alttan veya üstten / sağdan veya soldan tesisat girişine uygun</li>
    //                             <li>Komple sac veya cam kapaklı</li>
    //                             <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
    //                             <li>Özel kilit sistemli, Demonte kapaklı</li>
    //                             <li>TS 862 EN 3-7 e uygun ,TSE ve CE belgeli 6 kg kuru
    //                                 kimyevi tozlu yangın söndürme cihazı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>SIVA ALTI TÜP YANDA DİKEY TEK KAPAKLI YANGIN DOLAPLARI</figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Hortum</th>
    //                                     <th>Ölçüler</th>
    //                                     <th>Kapak</th>
    //                                     <th>Model</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>DT_031_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>900x710x220 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_032_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>900x710x220 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_033_YD</td>
    //                                     <td>30 mt.</td>
    //                                     <td>900x710x220 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_034_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>900x710x220 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_035_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>900x710x220 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_036_YD</td>
    //                                     <td>35 mt.</td>
    //                                     <td>900x710x220 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 7,
    //                 name: 'TÜP YANDA DİKEY SIVA ÜSTÜ TEK KAPAKLI YANGIN DOLAPLARI',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/tüp_yanda_dikey_tek_kapakli_yangin_dolaplari/image-110.png',
    //                 process.env.PUBLIC_URL + '/images/products/tüp_yanda_dikey_tek_kapakli_yangin_dolaplari/image-107.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/0/7/tüp_yanda_dikey_siva_ustu_tek_kapakli_yangin_dolaplari',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>10mm orfice</li>
    //                             <li>Ayarlanabilir (jet, spray, shut-off)</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
    //                             <li>12 ATM çalışma basıncı, </li>
    //                             <li>36 ATM patlama basıncı. </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>2" köşe vanalı</li>
    //                             <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Makara</figcaption>
    //                         <ul>
    //                             <li>Özel profilli çelik sactan mamül</li>
    //                             <li>Elektrostatik toz boyalı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Kabinet</figcaption>
    //                         <ul>
    //                             <li>1,20mm DKP sacdan mamül</li>
    //                             <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
    //                             <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
    //                             <li>Alttan veya üstten / sağdan veya soldan tesisat girişine uygun</li>
    //                             <li>Komple sac veya cam kapaklı</li>
    //                             <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
    //                             <li>Özel kilit sistemli, Demonte kapaklı</li>
    //                             <li>TS 862 EN 3-7 e uygun ,TSE ve CE belgeli 6 kg kuru
    //                                 kimyevi tozlu yangın söndürme cihazı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>SIVA ÜSTÜ TÜP YANDA DİKEY TEK KAPAKLI YANGIN DOLAPLARI</figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Hortum</th>
    //                                     <th>Ölçüler</th>
    //                                     <th>Kapak</th>
    //                                     <th>Model</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>DT_037_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>900x750x240 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_038_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>900x750x240 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_039_YD</td>
    //                                     <td>30 mt.</td>
    //                                     <td>900x750x240 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_040_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>900x750x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_041_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>900x750x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_042_YD</td>
    //                                     <td>35 mt.</td>
    //                                     <td>900x750x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 8,
    //                 name: 'CAM KAPAKLI STANDART YANGIN DOLAPLARI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/cam_kapakli_standart_yangin_dolaplari/image-118.png',
    //                     process.env.PUBLIC_URL + '/images/products/cam_kapakli_standart_yangin_dolaplari/image-119.png',
    //                     process.env.PUBLIC_URL + '/images/products/cam_kapakli_standart_yangin_dolaplari/image-120.png',
    //                     process.env.PUBLIC_URL + '/images/products/cam_kapakli_standart_yangin_dolaplari/image-117.png',
    //                     process.env.PUBLIC_URL + '/images/products/cam_kapakli_standart_yangin_dolaplari/image-121.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/0/8/cam_kapakli_standart_yangin_dolaplari',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>10mm orfice</li>
    //                             <li>Ayarlanabilir (jet, spray, shut-off)</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
    //                             <li>12 ATM çalışma basıncı, </li>
    //                             <li>36 ATM patlama basıncı. </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>2" köşe vanalı</li>
    //                             <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Makara</figcaption>
    //                         <ul>
    //                             <li>Özel profilli çelik sactan mamül</li>
    //                             <li>Elektrostatik toz boyalı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Kabinet</figcaption>
    //                         <ul>
    //                             <li>1,20mm DKP sacdan mamül</li>
    //                             <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
    //                             <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
    //                             <li>Alttan veya üstten / sağdan veya soldan tesisat girişine uygun</li>
    //                             <li>Komple cam kapaklı</li>
    //                             <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
    //                             <li>Özel kilit sistemli, Demonte kapaklı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>CAM KAPAKLI STANDART YANGIN DOLAPLARI</figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Hortum</th>
    //                                     <th>Ölçüler</th>
    //                                     <th>Kapak</th>
    //                                     <th>Model</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>DT_043_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>710x710x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_044_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>710x710x240 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_045_YD</td>
    //                                     <td>30 mt.</td>
    //                                     <td>710x710x240 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_046_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>700x700x200 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_047_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>750x750x240 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_048_YD</td>
    //                                     <td>35 mt.</td>
    //                                     <td>750x750x240 mm</td>
    //                                     <td>Camlı</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 9,
    //                 name: 'SAC KAPAKLI STANDART YANGIN DOLAPLARI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/sac_kapakli_standart_yangin_dolaplari/image-128.png',
    //                     process.env.PUBLIC_URL + '/images/products/sac_kapakli_standart_yangin_dolaplari/image-129.png',
    //                     process.env.PUBLIC_URL + '/images/products/sac_kapakli_standart_yangin_dolaplari/image-136.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/0/9/sac_kapakli_standart_yangin_dolaplari',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>10mm orfice</li>
    //                             <li>Ayarlanabilir (jet, spray, shut-off)</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
    //                             <li>12 ATM çalışma basıncı, </li>
    //                             <li>36 ATM patlama basıncı. </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>2" köşe vanalı</li>
    //                             <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Makara</figcaption>
    //                         <ul>
    //                             <li>Özel profilli çelik sactan mamül</li>
    //                             <li>Elektrostatik toz boyalı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Kabinet</figcaption>
    //                         <ul>
    //                             <li>1,20mm DKP sacdan mamül</li>
    //                             <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
    //                             <li>İstenilen RAL kodunda elektrostatik fırın boyalı</li>
    //                             <li>Alttan veya üstten / sağdan veya soldan tesisat girişine uygun</li>
    //                             <li>Komple sac kapaklı</li>
    //                             <li>Sıva altı - yarı gömme - sıva üstü seçenekleri</li>
    //                             <li>Özel kilit sistemli, Demonte kapaklı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>SAC KAPAKLI STANDART YANGIN DOLAPLARI</figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Hortum</th>
    //                                     <th>Ölçüler</th>
    //                                     <th>Kapak</th>
    //                                     <th>Model</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>DT_049_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>710x710x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_050_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>710x710x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_051_YD</td>
    //                                     <td>30 mt.</td>
    //                                     <td>710x710x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_052_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>700x700x200 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_053_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>750x750x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_054_YD</td>
    //                                     <td>35 mt.</td>
    //                                     <td>750x750x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 10,
    //                 name: 'SULU SİSTEM SAHRA TİPİ YANGIN DOLABI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/sulu_sistem_sahra_tipi_yangin_dolabi/image-139.png',
    //                     process.env.PUBLIC_URL + '/images/products/sulu_sistem_sahra_tipi_yangin_dolabi/image-138.png',
    //                 ],

    //                 content: '',
    //                 link: '/product/0/10/sulu_sistem_sahra_tipi_yangin_dolabi',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Nozul : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>10mm orfice</li>
    //                             <li>Ayarlanabilir (jet, spray, shut-off)</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
    //                             <li>12 ATM çalışma basıncı, </li>
    //                             <li>36 ATM patlama basıncı. </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>2" köşe vanalı</li>
    //                             <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Makara</figcaption>
    //                         <ul>
    //                             <li>Özel profilli çelik sactan mamül</li>
    //                             <li>Elektrostatik toz boyalı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Kabinet</figcaption>
    //                         <ul>
    //                             <li>1,5mm GALVANİZ sacdan mamül</li>
    //                             <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
    //                             <li>RAL 3002 renginde elektrostatik toz fırın boyalı</li>
    //                             <li>Sağdan veya soldan tesisat girişine uygun</li>
    //                             <li>Komple sac kapaklı</li>
    //                             <li>Araz koşullarına karşı su geçirmez detaylı, çatılı ve ayaklı</li>
    //                             <li>Özel kilit sistemli, Demonte kapaklı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>SULU SİSTEM SAHRA TİPİ YANGIN DOLAPLARI</figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Hortum</th>
    //                                     <th>Ölçüler</th>
    //                                     <th>Kapak</th>
    //                                     <th>Model</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>DT_055_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>750x750x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Arazi Tipi</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_056_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>750x750x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Arazi Tipi</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_057_YD</td>
    //                                     <td>30 mt.</td>
    //                                     <td>750x750x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Arazi Tipi</td>
    //                                 </tr>

    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 11,
    //                 name: 'BEZLİ SİSTEM SAHRA TİPİ YANGIN DOLABI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/bezli_sistem_sahra_tipi_yangin_dolabi/image-141.png',
    //                     process.env.PUBLIC_URL + '/images/products/bezli_sistem_sahra_tipi_yangin_dolabi/image-140.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/0/11/bezli_sistem_sahra_tipi_yangin_dolabi',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Lans : <span>2" (veya 2 1/2)"</span></figcaption>
    //                         <ul>
    //                             <li>10mm orfice</li>
    //                             <li>Ayarlanabilir düz veya kumandalı(jet, spray, shut-off)</li>
    //                             <li>TSE 3145 Standartına uygun ve belgeli</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Hortum : <span>2" (DN50) veya 2 1/2" ( DN 65)</span></figcaption>
    //                         <ul>
    //                             <li>TSE EN9222 Standartına uygun ve belgeli</li>
    //                             <li>17 ATM çalışma basıncı, </li>
    //                             <li>50 ATM patlama basıncı. </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Rekor : <span>2" veya 2 1/2"</span></figcaption>
    //                         <ul>
    //                             <li>TS 12258 Standartına uygun ve belgeli</li>
    //                         </ul>
    //                     </figure>
    //                     {/* <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>2" köşe vanalı</li>
    //                             <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
    //                         </ul>
    //                     </figure> */}
    //                     <figure>
    //                         <figcaption>Makara</figcaption>
    //                         <ul>
    //                             <li>Özel profilli çelik sactan mamül</li>
    //                             <li>Elektrostatik toz boyalı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Kabinet</figcaption>
    //                         <ul>
    //                             <li>1,5mm GALVANİZ sacdan mamül</li>
    //                             <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
    //                             <li>RAL 3002 renginde elektrostatik toz fırın boyalı</li>
    //                             <li>Sağdan veya soldan tesisat girişine uygun</li>
    //                             <li>Komple sac kapaklı</li>
    //                             <li>Araz koşullarına karşı su geçirmez detaylı, çatılı ve ayaklı</li>
    //                             <li>Özel kilit sistemli, Demonte kapaklı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>BEZLİ SİSTEM SAHRA TİPİ YANGIN DOLAPLARI</figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Hortum</th>
    //                                     <th>Ölçüler</th>
    //                                     <th>Kapak</th>
    //                                     <th>Model</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>DT_058_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>750x750x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Arazi Tipi</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_059_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>750x750x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Arazi Tipi</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_060_YD</td>
    //                                     <td>30 mt.</td>
    //                                     <td>750x750x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Arazi Tipi</td>
    //                                 </tr>

    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 12,
    //                 name: 'ÇİFT MAKARALI BEZLİ SİSTEM SAHRA TİPİ YANGIN DOLAPLARI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/cift_makarali_bezli_sistem_sahra_tipi_yangin_dolaplari/image-162.png',
    //                     process.env.PUBLIC_URL + '/images/products/cift_makarali_bezli_sistem_sahra_tipi_yangin_dolaplari/image-156.png',
    //                     process.env.PUBLIC_URL + '/images/products/cift_makarali_bezli_sistem_sahra_tipi_yangin_dolaplari/image-157.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/0/12/cift_makarali_bezli_sistem_sahra_tipi_yangin_dolaplari',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Lans : <span>2" (veya 2 1/2)"</span></figcaption>
    //                         <ul>
    //                             <li>10mm orfice</li>
    //                             <li>Ayarlanabilir düz veya kumandalı(jet, spray, shut-off)</li>
    //                             <li>TSE 3145 Standartına uygun ve belgeli</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Hortum : <span>2" (DN50) veya 2 1/2" ( DN 65)</span></figcaption>
    //                         <ul>
    //                             <li>TSE EN9222 Standartına uygun ve belgeli</li>
    //                             <li>17 ATM çalışma basıncı, </li>
    //                             <li>50 ATM patlama basıncı. </li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Rekor : <span>2" veya 2 1/2"</span></figcaption>
    //                         <ul>
    //                             <li>TS 12258 Standartına uygun ve belgeli</li>
    //                         </ul>
    //                     </figure>
    //                     {/* <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>2" köşe vanalı</li>
    //                             <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
    //                         </ul>
    //                     </figure> */}
    //                     <figure>
    //                         <figcaption>Makara</figcaption>
    //                         <ul>
    //                             <li>Özel profilli çelik sactan mamül</li>
    //                             <li>Elektrostatik toz boyalı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Kabinet</figcaption>
    //                         <ul>
    //                             <li>1,5mm GALVANİZ sacdan mamül</li>
    //                             <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
    //                             <li>RAL 3002 renginde elektrostatik toz fırın boyalı</li>
    //                             <li>Sağdan veya soldan tesisat girişine uygun</li>
    //                             <li>Komple sac kapaklı</li>
    //                             <li>Araz koşullarına karşı su geçirmez detaylı, çatılı ve ayaklı</li>
    //                             <li>Özel kilit sistemli, Demonte kapaklı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>BEZLİ SİSTEM SAHRA TİPİ YANGIN DOLAPLARI</figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Hortum</th>
    //                                     <th>Ölçüler</th>
    //                                     <th>Kapak</th>
    //                                     <th>Model</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>DT_061_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>70x115x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Arazi Tipi</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_062_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>70x115x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Arazi Tipi</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_063_YD</td>
    //                                     <td>30 mt.</td>
    //                                     <td>70x115x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Arazi Tipi</td>
    //                                 </tr>

    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 13,
    //                 name: 'KÖPÜKLÜ YANGIN DOLABI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/köpüklü_yangin_dolabi/image-155.png',
    //                     process.env.PUBLIC_URL + '/images/products/köpüklü_yangin_dolabi/image-154.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/0/13/köpüklü_yangin_dolabi',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Nozul : <span>1" (Ø 25mm)"</span></figcaption>
    //                         <ul>
    //                             <li>Özel köpük yapma lansı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
    //                             <li>12 ATM çalışma basıncı</li>
    //                             <li>36 ATM patlama basıncı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>1" küresel vanalı</li>
    //                         </ul>
    //                     </figure>
    //                     {/* <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>2" köşe vanalı</li>
    //                             <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
    //                         </ul>
    //                     </figure> */}
    //                     <figure>
    //                         <figcaption>Makara</figcaption>
    //                         <ul>
    //                             <li>Özel profilli çelik sactan mamül</li>
    //                             <li>Elektrostatik toz boyalı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Menenjör</figcaption>
    //                         <ul>
    //                             <li>1''özel ayarlanabilir köpük oranlayıcı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Tank</figcaption>
    //                         <ul>
    //                             <li>40 lt kapasiteli özel fiber malzemeden üretilmiştir.</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Kabinet</figcaption>
    //                         <ul>
    //                             <li>1,5mm GALVANİZ sacdan mamül</li>
    //                             <li>Kapak ve makara mekanizması,tadilatsız sağ veya sol uygulamalı</li>
    //                             <li>RAL 3002 renginde elektrostatik toz fırın boyalı</li>
    //                             <li>Sağdan veya soldan tesisat girişine uygun</li>
    //                             <li>Komple sac kapaklı</li>
    //                             <li>Araz koşullarına karşı su geçirmez detaylı, çatılı ve ayaklı</li>
    //                             <li>Özel kilit sistemli, Demonte kapaklı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>KÖPÜKLÜ YANGIN DOLABI</figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Hortum</th>
    //                                     <th>Ölçüler</th>
    //                                     <th>Kapak</th>
    //                                     <th>Model</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>DT_064_YD</td>
    //                                     <td>20 mt.</td>
    //                                     <td>750x1150x300 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Köpüklü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_065_YD</td>
    //                                     <td>25 mt.</td>
    //                                     <td>750x1150x300 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Köpüklü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_066_YD</td>
    //                                     <td>30 mt.</td>
    //                                     <td>750x1150x300 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Köpüklü</td>
    //                                 </tr>

    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 14,
    //                 name: 'SULU SİSTEM HORTUM ARABALARI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/sulu_sistem_hortum_arabalari/image-172.png',
    //                     process.env.PUBLIC_URL + '/images/products/sulu_sistem_hortum_arabalari/image-173.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/0/14/sulu_sistem_hortum_arabalari',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Nozul : <span>1" (Ø 25mm)"</span></figcaption>
    //                         <ul>
    //                             <li>10mm orfice</li>
    //                             <li>Ayarlanabilir (jet, spray, shut-off)</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
    //                             <li>12 ATM çalışma basıncı</li>
    //                             <li>36 ATM patlama basıncı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>2" köşe vanalı, 2"-1" adaptörlü veya 1" küresel vanalı</li>
    //                         </ul>
    //                     </figure>
    //                     {/* <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>2" köşe vanalı</li>
    //                             <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
    //                         </ul>
    //                     </figure> */}
    //                     <figure>
    //                         <figcaption>Makara</figcaption>
    //                         <ul>
    //                             <li>1.5 mm Galvaniz sactan pres baskı ile imal edilmiştir.</li>
    //                             <li> 0.70 Mikron elektrostatik toz boyalı RAL 3002 kırmızı </li>
    //                             <li>Geri sarma işlemini basitleştiren krank sistemi </li>
    //                             <li>Makara göbeği paslanmaz malzemeden üretilmiştir. </li>
    //                             <li>İsteğe bağlı olarak 40-50-60 mt üretilmektedir.</li>
    //                         </ul>
    //                     </figure>


    //                 </div>
    //             },
    //             {
    //                 id: 15,
    //                 name: 'ŞAFT İÇİ SULU SİSTEM YANGIN MAKARASI',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/saft_içi_sulu_sistem_yangin_makarasi/image-169.png'],
    //                 content: '',
    //                 link: '/product/0/15/saft_içi_sulu_sistem_yangin_makarasi',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Nozul : <span>1" (Ø 25mm)"</span></figcaption>
    //                         <ul>
    //                             <li>10mm orfice</li>
    //                             <li>Ayarlanabilir (jet, spray, shut-off)</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Hortum : <span>1" (Ø 25mm)</span></figcaption>
    //                         <ul>
    //                             <li>Yarı sert EN 694 standartına uygun ve belgeli</li>
    //                             <li>12 ATM çalışma basıncı</li>
    //                             <li>36 ATM patlama basıncı</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>2"veya1" küresel vana</li>
    //                         </ul>
    //                     </figure>
    //                     {/* <figure>
    //                         <figcaption>Vana</figcaption>
    //                         <ul>
    //                             <li>2" köşe vanalı</li>
    //                             <li>2"-1" adaptörlü veya 1" küresel vanalı </li>
    //                         </ul>
    //                     </figure> */}
    //                     <figure>
    //                         <figcaption>Makara</figcaption>
    //                         <ul>
    //                             <li>Özel profilli çelik sactan mamül</li>
    //                             <li>Elektrostatik toz boyalı,</li>
    //                             <li>İsteğe bağlı 20-25-30 mt üretim</li>
    //                         </ul>
    //                     </figure>


    //                 </div>
    //             },
    //             {
    //                 id: 16,
    //                 name: 'KORUYUCU MALZEME DOLAPLARI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/koruyucu_malzeme_dolabi/image-178.png',
    //                     process.env.PUBLIC_URL + '/images/products/koruyucu_malzeme_dolabi/image-175.png',
    //                     process.env.PUBLIC_URL + '/images/products/koruyucu_malzeme_dolabi/image-176.png',
    //                     process.env.PUBLIC_URL + '/images/products/koruyucu_malzeme_dolabi/image-177.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/0/16/köpüklü_yangin_dolabi',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Kabinet</figcaption>
    //                         <ul>
    //                             <li>1,5mm GALVANİZ sacdan mamül</li>
    //                             <li>Kapak tadilatsız sağ veya sol uygulamalı</li>
    //                             <li>RAL 3002 renginde elektrostatik toz fırın boyalı</li>
    //                             <li>Komple sac kapaklı</li>
    //                             <li>Özel kilit sistemli, Demonte kapaklı</li>
    //                             <li>Ayarlanabilir raf özellikli</li>
    //                             <li>Ayaklı</li>
    //                             <li>İstenilen ölçülerde üretilebilir</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>KORUYUCU EKİPMAN MALZEME DOLAPLARI </figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Ölçüler</th>
    //                                     <th>Kapak</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>DT_067_YD</td>
    //                                     <td>700x1500x500 mm</td>
    //                                     <td>Sac</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_068_YD</td>
    //                                     <td>700x1500x500 mm</td>
    //                                     <td>Camlı</td>
    //                                 </tr>


    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 17,
    //                 name: 'YANGIN SÖNDÜRME CİHAZI DOLAPLARI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/yangin_sondurme_cihazi_dolaplari/image-174.png',
    //                     process.env.PUBLIC_URL + '/images/products/yangin_sondurme_cihazi_dolaplari/image-185.png',
    //                     process.env.PUBLIC_URL + '/images/products/yangin_sondurme_cihazi_dolaplari/image-186.png',
    //                     process.env.PUBLIC_URL + '/images/products/yangin_sondurme_cihazi_dolaplari/image-187.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/0/17/yangin_sondurme_cihazi_dolaplari',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>

    //                     <figure>
    //                         <figcaption>YANGIN SÖNDÜRME CİHAZI DOLAPLARI </figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Ölçüler</th>
    //                                     <th>Kapak</th>
    //                                     <th>Model</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>DT_069_YD</td>
    //                                     <td>210x710x210 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_070_YD</td>
    //                                     <td>210x710x210 mm</td>
    //                                     <td>Cam</td>
    //                                     <td>Sıva Altı</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_071_YD</td>
    //                                     <td>300x730x240 mm</td>
    //                                     <td>Sac</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_072_YD</td>
    //                                     <td>300x730x240 mm</td>
    //                                     <td>Cam</td>
    //                                     <td>Sıva Üstü</td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //         ]
    //     }
    // },
    // {
    //     category: {
    //         id: 3,
    //         name: '2020',
    //         imgsrc: [process.env.PUBLIC_URL + '/images/category/2020.jpeg'],
    //         content: 'EN 16034  Standardına uygun , İSO belgeli, çeşitli model ve özelliklerde yangın kapıları.',
    //         link: '/category/yanginhortumlari/3/subcategories',
    //         subcategories: [
    //             {
    //                 id: 0,
    //                 name: 'TEK KANAT YANGIN KAPILARI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/tek_kanat_yangin_kapilari/2.jpg',
    //                     process.env.PUBLIC_URL + '/images/products/tek_kanat_yangin_kapilari/image-199.png',
    //                     process.env.PUBLIC_URL + '/images/products/tek_kanat_yangin_kapilari/image-197.png',
    //                     process.env.PUBLIC_URL + '/images/products/tek_kanat_yangin_kapilari/image-200.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/3/0/tek_kanat_yangin_kapilari',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>TEKNİK ÖZELLİKLER </figcaption>
    //                         <ul>
    //                             <li>Yangın kapılarımız TSE EN 16034 standardına sahip olup aşağıdaki özelliklerde üretilmektedir.</li>
    //                             <li>KASA : Projeye göre  1,2 -1,5 mm kalınlıklarında DKP veya Galvaniz sacdan ,ayarlı tam kasa veya yarım kasa olarak üretilmektedir.</li>
    //                             <li>KANAT : Projeye göre  1 -1,2 mm kalınlıklarında DKP veya Galvaniz sacdan ,tek kanat  veya çift kanat olarak üretilmektedir.</li>
    //                             <li>FİTİL: 170 °C genişleyerek boşlukları kapatan intumesan fitil ve ses contası kullanılmaktadır.</li>
    //                             <li>BOYA : Proje bazlı istenilen renkte elektrostatik toz boya ile boyanmaktadır.</li>
    //                             <li>MENTEŞE : 1 adet yaylı , 1 adet yaysız menteşe kullanıldığından kapılar kendiliğinden kapanmaktadır.</li>
    //                             <li>AKSESUAR: Projeye göre yüzeysel ve gömme sistem panik bar ,kilitli veya kilitsiz dış kol ,hidrolik kapı kapatıcı, kapı sıralama mekanizması ,manyetik kapı tutucu ,kapı altı otomatik giyotin kullanılmaktadır.</li>
    //                         </ul>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 1,
    //                 name: 'ÇİFT KANAT YANGIN KAPILARI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/cift_kanat_yangin_kapilari/image-201.png',
    //                     process.env.PUBLIC_URL + '/images/products/cift_kanat_yangin_kapilari/image-202.png',
    //                     process.env.PUBLIC_URL + '/images/products/cift_kanat_yangin_kapilari/image-203.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/3/1/cift_kanat_yangin_kapilari',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>TEKNİK ÖZELLİKLER </figcaption>
    //                         <ul>
    //                             <li>Yangın kapılarımız TSE EN 16034 standardına sahip olup aşağıdaki özelliklerde üretilmektedir.</li>
    //                             <li>KASA : Projeye göre  1,2 -1,5 mm kalınlıklarında DKP veya Galvaniz sacdan ,ayarlı tam kasa veya yarım kasa olarak üretilmektedir.</li>
    //                             <li>KANAT : Projeye göre  1 -1,2 mm kalınlıklarında DKP veya Galvaniz sacdan ,tek kanat  veya çift kanat olarak üretilmektedir.</li>
    //                             <li>FİTİL: 170 °C genişleyerek boşlukları kapatan intumesan fitil ve ses contası kullanılmaktadır.</li>
    //                             <li>BOYA : Proje bazlı istenilen renkte elektrostatik toz boya ile boyanmaktadır.</li>
    //                             <li>MENTEŞE : 1 adet yaylı , 1 adet yaysız menteşe kullanıldığından kapılar kendiliğinden kapanmaktadır.</li>
    //                             <li>AKSESUAR: Projeye göre yüzeysel ve gömme sistem panik bar ,kilitli veya kilitsiz dış kol ,hidrolik kapı kapatıcı, kapı sıralama mekanizması ,manyetik kapı tutucu ,kapı altı otomatik giyotin kullanılmaktadır.</li>
    //                         </ul>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 2,
    //                 name: 'CAMLI YANGIN KAPILARI',
    //                 imgsrc: [
    //                     process.env.PUBLIC_URL + '/images/products/camli_yangin_kapilari/image-208.png',
    //                     process.env.PUBLIC_URL + '/images/products/camli_yangin_kapilari/image-207.png',
    //                     // process.env.PUBLIC_URL + '/images/products/camli_yangin_kapilari/image-206.png',
    //                     // process.env.PUBLIC_URL + '/images/products/camli_yangin_kapilari/image-205.png',
    //                 ],
    //                 content: '',
    //                 link: '/product/3/2/camli_yangin_kapilari',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>TEKNİK ÖZELLİKLER </figcaption>
    //                         <ul>
    //                             <li>Yangın kapılarımız TSE EN 16034 standardına sahip olup aşağıdaki özelliklerde üretilmektedir.</li>
    //                             <li>KASA : Projeye göre  1,2 -1,5 mm kalınlıklarında DKP veya Galvaniz sacdan ,ayarlı tam kasa veya yarım kasa olarak üretilmektedir.</li>
    //                             <li>KANAT : Projeye göre  1 -1,2 mm kalınlıklarında DKP veya Galvaniz sacdan ,tek kanat  veya çift kanat olarak üretilmektedir.</li>
    //                             <li>FİTİL: 170 °C genişleyerek boşlukları kapatan intumesan fitil ve ses contası kullanılmaktadır.</li>
    //                             <li>BOYA : Proje bazlı istenilen renkte elektrostatik toz boya ile boyanmaktadır.</li>
    //                             <li>MENTEŞE : 1 adet yaylı , 1 adet yaysız menteşe kullanıldığından kapılar kendiliğinden kapanmaktadır.</li>
    //                             <li>AKSESUAR: Projeye göre yüzeysel ve gömme sistem panik bar ,kilitli veya kilitsiz dış kol ,hidrolik kapı kapatıcı, kapı sıralama mekanizması ,manyetik kapı tutucu ,kapı altı otomatik giyotin kullanılmaktadır.</li>
    //                         </ul>
    //                     </figure>
    //                 </div>
    //             },
    //         ]
    //     }
    // },
    // {
    //     category: {
    //         id: 1,
    //         name: '2022',
    //         imgsrc: [process.env.PUBLIC_URL + '/images/category/2022.jpeg'],
    //         content: 'ISO 9001:2014 kalite yönetim standartlarına uygun TS 9222 belgeli yangın hortumları.',
    //         link: '/category/yanginhortumlari/1/subcategories',
    //         subcategories: [
    //             {
    //                 id: 0,
    //                 name: 'YARI SERT KAUÇUK YANGIN HORTUMLARI',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_hortumlari_rekorlari/image-193.png'],
    //                 content: '',
    //                 link: '/product/1/0/yari_sert_kaucuk_yangin_hortumlari',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Özellikler</figcaption>
    //                         <ul>
    //                             <li>TS EN 694 Yarı sert kauçuk malzemeden imal edilmiştir.</li>
    //                             <li>Kullanıldıktan sonra kurutmaya gerek yoktur.</li>
    //                             <li>Sulu sistem yangın dolapları ve mobil makaralarda kullanıma uygundur.</li>
    //                             <li>Üretildiği ölçüler : ¾”ten 1″e kadar istenilen uzunlukta</li>
    //                             <li>Çalışma basıncı : 12 Bar</li>
    //                             <li>Test basıncı : 18 Bar</li>
    //                             <li>Patlama basıncı : 36 Bar</li>
    //                         </ul>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 6,
    //                 name: 'İÇİ KAUÇUK YASSI YANGIN HORTUMU',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/category/hortum.jpg'],
    //                 content: '',
    //                 link: '/product/1/6/yari_sert_kaucuk_yangin_hortumlari',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>TEKNİK VERİLER </figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>İç Çap (Mm - İnç)</th>
    //                                     <th>Patlama Basıncı (Bar - Psi)</th>
    //                                     <th>Çalışma Basıncı (Bar - Psi)</th>
    //                                     <th>Ağırlık +/-%5 (g/m)</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>DT_001_YH</td>
    //                                     <td>25 - 1"</td>
    //                                     <td>60 - 840</td>
    //                                     <td>20 - 280</td>
    //                                     <td>130</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_002_YH</td>
    //                                     <td>38 - 1 <sup>1/2″</sup></td>
    //                                     <td>50 - 700</td>
    //                                     <td>17 - 240</td>
    //                                     <td>195</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_003_YH</td>
    //                                     <td>45 - 1 <sup>3/4″</sup></td>
    //                                     <td>50 - 700</td>
    //                                     <td>17 - 240</td>
    //                                     <td>230</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_004_YH</td>
    //                                     <td>52 - 2"</td>
    //                                     <td>50 - 700</td>
    //                                     <td>17 - 240</td>
    //                                     <td>250</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_005_YH</td>
    //                                     <td>65 - 2 <sup>1/2″</sup></td>
    //                                     <td>50 - 700</td>
    //                                     <td>17 - 240</td>
    //                                     <td>365</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_006_YH</td>
    //                                     <td>70 - 2 <sup>3/4″</sup></td>
    //                                     <td>50 - 700</td>
    //                                     <td>17 - 240</td>
    //                                     <td>395</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_007_YH</td>
    //                                     <td>70 - 2 <sup>3/4″</sup></td>
    //                                     <td>50 - 700</td>
    //                                     <td>17 - 240</td>
    //                                     <td>395</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_008_YH</td>
    //                                     <td>75 -3"</td>
    //                                     <td>37 - 550</td>
    //                                     <td>13 - 180</td>
    //                                     <td>405</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_009_YH</td>
    //                                     <td>102 - 4"</td>
    //                                     <td>30 - 425</td>
    //                                     <td>10 - 140</td>
    //                                     <td>560</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_010_YH</td>
    //                                     <td>125 - 5"</td>
    //                                     <td>20</td>
    //                                     <td>7 - 110</td>
    //                                     <td>750</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>DT_011_YH</td>
    //                                     <td>150 - 6"</td>
    //                                     <td>20 - 285</td>
    //                                     <td>7 - 110</td>
    //                                     <td>900</td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 1,
    //                 name: 'HORTUM REKORLARI',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_hortumlari_rekorlari/1.jpg'],
    //                 content: '',
    //                 link: '/product/1/1/yangin_hortumlari_rekorlari',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Özellikler</figcaption>
    //                         <ul>
    //                             <li>Etial 160</li>
    //                             <li>Pres Enjeksiyon Baskı</li>
    //                             <li>Açma - Kapama</li>
    //                             <li>Moment 3-5 Nm</li>
    //                             <li>Basma Kaplin İşletme</li>
    //                             <li>Basıncı 12 Bar</li>
    //                             <li>Mukavemet 24 Bar</li>
    //                             <li>TS 12258</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>TEKNİK VERİLER </figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Tırnak</th>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Cinsi</th>
    //                                     <th>B1</th>
    //                                     <th>B2</th>
    //                                     <th>L</th>
    //                                     <th>D1</th>
    //                                     <th>Ağırlık</th>
    //                                     <th>Koli Adet</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>HR-25</td>
    //                                     <td>GNF 5000</td>
    //                                     <td>1" Hortum Rekoru</td>
    //                                     <td>18 mm</td>
    //                                     <td>25 mm</td>
    //                                     <td>53 mm</td>
    //                                     <td>31 mm</td>
    //                                     <td>0,095</td>
    //                                     <td></td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>HR-25</td>
    //                                     <td>GNF 5010</td>
    //                                     <td>3/4" Hortum Rekoru</td>
    //                                     <td>14 mm</td>
    //                                     <td>20 mm</td>
    //                                     <td>53 mm</td>
    //                                     <td>31 mm</td>
    //                                     <td>0,095</td>
    //                                     <td></td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>HR-38</td>
    //                                     <td>GNF 5020</td>
    //                                     <td>1 <sup>1/2″</sup> Hortum Rekoru</td>
    //                                     <td>28 mm</td>
    //                                     <td>38 mm</td>
    //                                     <td>90 mm</td>
    //                                     <td>51 mm</td>
    //                                     <td>0,235</td>
    //                                     <td>42</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>HR-C52</td>
    //                                     <td>GNF 5030</td>
    //                                     <td>2″ Hortum Rekoru</td>
    //                                     <td>18 mm</td>
    //                                     <td>28 mm</td>
    //                                     <td>80 mm</td>
    //                                     <td>66 mm</td>
    //                                     <td>0,375</td>
    //                                     <td>36</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>HR-C52</td>
    //                                     <td>GNF 5040</td>
    //                                     <td>2″ Hortum Rekoru</td>
    //                                     <td>42 mm</td>
    //                                     <td>52 mm</td>
    //                                     <td>90 mm</td>
    //                                     <td>66 mm</td>
    //                                     <td>0,400</td>
    //                                     <td>36</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>HR-B75</td>
    //                                     <td>GNF 5050-65</td>
    //                                     <td>2 <sup>1/2″</sup> Hortum Rekoru</td>
    //                                     <td>55 mm</td>
    //                                     <td>65 mm</td>
    //                                     <td>125 mm</td>
    //                                     <td>89 mm</td>
    //                                     <td>0,765</td>
    //                                     <td>30</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>HR-B75</td>
    //                                     <td>GNF 5050-70</td>
    //                                     <td>2 <sup>3/4″</sup> Hortum Rekoru</td>
    //                                     <td>60 mm</td>
    //                                     <td>70 mm</td>
    //                                     <td>125 mm</td>
    //                                     <td>89 mm</td>
    //                                     <td>0,765</td>
    //                                     <td>30</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>HR-B75</td>
    //                                     <td>GNF 5060</td>
    //                                     <td>3″ Hortum Rekoru</td>
    //                                     <td>65 mm</td>
    //                                     <td>75 mm</td>
    //                                     <td>125 mm</td>
    //                                     <td>89 mm</td>
    //                                     <td>0,790</td>
    //                                     <td>30</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>HR-A110</td>
    //                                     <td>GNF 5070</td>
    //                                     <td>4″ Hortum Rekoru</td>
    //                                     <td>90 mm</td>
    //                                     <td>101 mm</td>
    //                                     <td>170 mm</td>
    //                                     <td>133 mm</td>
    //                                     <td>1,505</td>
    //                                     <td>12</td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 2,
    //                 name: 'HORTUM REKOR ANAHTARLARI',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/yangin_hortumlari_rekorlari/unnamed.jpg'],
    //                 content: '',
    //                 link: '/product/1/2/yangin_hortumlari_rekor_anahtarlari',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Özellikler</figcaption>
    //                         <ul>
    //                             <li>8 mm Sactan Lazer Kesim</li>
    //                             <li>Çinko Kaplama</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>TEKNİK VERİLER </figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>

    //                                     <th>Ürün Kodu</th>
    //                                     <th>Cinsi</th>
    //                                     <th>W</th>
    //                                     <th>Ağırlık</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>GNF 8500</td>
    //                                     <td>2″-2 <sup>1/2″</sup> Hidrant Anahtarı</td>
    //                                     <td>69 mm</td>
    //                                     <td>0,795</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>GNF 8510</td>
    //                                     <td>2″-2 <sup>1/2″</sup> Rekor Anahtarı</td>
    //                                     <td></td>
    //                                     <td>0,430</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>GNF 8520</td>
    //                                     <td>2 <sup>1/2″</sup>-4″ Rekor Anahtarı</td>
    //                                     <td></td>
    //                                     <td>0,645</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>GNF 8501</td>
    //                                     <td>Çok Amaçlı Hidrant Anahtarı</td>
    //                                     <td></td>
    //                                     <td></td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //         ]
    //     }
    // },
    // {
    //     category: {
    //         id: 2,
    //         name: '2023',
    //         imgsrc: [process.env.PUBLIC_URL + '/images/category/2023.jpeg'],
    //         content: 'ISO 9001:2014 kalite yönetim standartlarına uygun TS 3145 belgeli lanslar.',
    //         link: '/category/lanslar/2/subcategories',
    //         subcategories: [
    //             {
    //                 id: 0,
    //                 name: 'DÜZ LANSLAR',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/lanslar/media582324ca3846a.png'],
    //                 content: '',
    //                 link: '/product/2/0/duz_lanslar',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>TEKNİK VERİLER </figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Tırnak</th>
    //                                     <th>Cinsi</th>
    //                                     <th>Ağırlık</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>GNF7015</td>
    //                                     <td>İDR-38</td>
    //                                     <td>1<sup>1/2"</sup> Pvc Düz Lans</td>
    //                                     <td>0,395</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>GNF7020</td>
    //                                     <td>İDR-52</td>
    //                                     <td>2" Pvc Düz Lans</td>
    //                                     <td>0,480</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>GNF7005</td>
    //                                     <td>İDR-38</td>
    //                                     <td>1<sup>1/2"</sup> Alm Düz Lans</td>
    //                                     <td>0,750</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>GNF7000</td>
    //                                     <td>İDR-52</td>
    //                                     <td>2" Alm Düz Lans</td>
    //                                     <td>0,840</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>GNF7010</td>
    //                                     <td>İDR-65</td>
    //                                     <td>2 <sup>1/2"</sup> Palm Düz Lans</td>
    //                                     <td>1,250</td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 1,
    //                 name: 'KUMANDALI LANSLAR',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/lanslar/kumandali-lans-e134.jpeg'],
    //                 content: '',
    //                 link: '/product/2/1/kumandali_lanslar',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Özellikler</figcaption>
    //                         <ul>
    //                             <li>Etial 160 Malzeme</li>
    //                             <li>TSE 3145</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>TEKNİK VERİLER </figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Tırnak</th>
    //                                     <th>Cinsi</th>
    //                                     <th>Ağırlık</th>
    //                                     <th>Koli Adeti</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>GNF7105</td>
    //                                     <td>İDR-38</td>
    //                                     <td>1<sup>1/2"</sup> Kumandalı Lans Alm</td>
    //                                     <td>1,370</td>
    //                                     <td>25</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>GNF7100</td>
    //                                     <td>İDR-52</td>
    //                                     <td>2" Kumandalı Lans Alm</td>
    //                                     <td>1,475</td>
    //                                     <td>30</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>GNF7110</td>
    //                                     <td>İDR-65</td>
    //                                     <td>2<sup>1/2"</sup> Kumandalı Lans Alm</td>
    //                                     <td>1,860</td>
    //                                     <td>13</td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //             {
    //                 id: 2,
    //                 name: 'PERDELİ LANSLAR',
    //                 imgsrc: [process.env.PUBLIC_URL + '/images/products/lanslar/Perdeleme-Lans-705x564.jpg'],
    //                 content: '',
    //                 link: '/product/2/2/perdeli_lanslar',
    //                 detail: <div className="detail">
    //                     <h2>Teknik Özellikler</h2>
    //                     <figure>
    //                         <figcaption>Özellikler</figcaption>
    //                         <ul>
    //                             <li>Etial 160 Malzeme</li>
    //                             <li>TSE 3145</li>
    //                         </ul>
    //                     </figure>
    //                     <figure>
    //                         <figcaption>TEKNİK VERİLER </figcaption>
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Ürün Kodu</th>
    //                                     <th>Tırnak</th>
    //                                     <th>Cinsi</th>
    //                                     <th>Ağırlık</th>
    //                                     <th>Koli Adeti</th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 <tr>
    //                                     <td>GNF7145</td>
    //                                     <td>İDR-38</td>
    //                                     <td>1<sup>1/2"</sup> Kumandalı Perdeli Lans Alm</td>
    //                                     <td>1,750</td>
    //                                     <td>25</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>GNF7150</td>
    //                                     <td>İDR-52</td>
    //                                     <td>2" Kumandalı Perdeli Lans Alm</td>
    //                                     <td>1,870</td>
    //                                     <td>30</td>
    //                                 </tr>
    //                                 <tr>
    //                                     <td>GNF7155</td>
    //                                     <td>İDR-65</td>
    //                                     <td>2<sup>1/2"</sup> Kumandalı Perdeli Lans Alm</td>
    //                                     <td>2,160</td>
    //                                     <td>13</td>
    //                                 </tr>
    //                             </tbody>
    //                         </table>
    //                     </figure>
    //                 </div>
    //             },
    //         ]
    //     }
    // },
]

export default data;